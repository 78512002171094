/**
 * Generated from \Zend_Measure dir
 *
 * @author /Users/robert/Dropbox/Projects/heliefb-api/scripts/create-js-measure-from-zend.php
 **/

var MathConvertUnit = (function(){
    var _public = {},_private = {};

    _public.unitTypeMap = {
	"CENTIGAL":"ACCELERATION",
	"CENTIMETER_PER_SQUARE_SECOND":"ACCELERATION",
	"DECIGAL":"ACCELERATION",
	"DECIMETER_PER_SQUARE_SECOND":"ACCELERATION",
	"DEKAMETER_PER_SQUARE_SECOND":"ACCELERATION",
	"FOOT_PER_SQUARE_SECOND":"ACCELERATION",
	"G":"ACCELERATION",
	"GAL":"ACCELERATION",
	"GALILEO":"ACCELERATION",
	"GRAV":"ACCELERATION",
	"HECTOMETER_PER_SQUARE_SECOND":"ACCELERATION",
	"INCH_PER_SQUARE_SECOND":"ACCELERATION",
	"KILOMETER_PER_HOUR_SECOND":"ACCELERATION",
	"KILOMETER_PER_SQUARE_SECOND":"ACCELERATION",
	"METER_PER_SQUARE_SECOND":"ACCELERATION",
	"MILE_PER_HOUR_MINUTE":"ACCELERATION",
	"MILE_PER_HOUR_SECOND":"ACCELERATION",
	"MILE_PER_SQUARE_SECOND":"ACCELERATION",
	"MILLIGAL":"ACCELERATION",
	"MILLIMETER_PER_SQUARE_SECOND":"ACCELERATION",
	"BIT":"BINARY",
	"CRUMB":"BINARY",
	"NIBBLE":"BINARY",
	"BYTE":"BINARY",
	"KILOBYTE":"BINARY",
	"KIBIBYTE":"BINARY",
	"KILO_BINARY_BYTE":"BINARY",
	"KILOBYTE_SI":"BINARY",
	"MEGABYTE":"BINARY",
	"MEBIBYTE":"BINARY",
	"MEGA_BINARY_BYTE":"BINARY",
	"MEGABYTE_SI":"BINARY",
	"GIGABYTE":"BINARY",
	"GIBIBYTE":"BINARY",
	"GIGA_BINARY_BYTE":"BINARY",
	"GIGABYTE_SI":"BINARY",
	"TERABYTE":"BINARY",
	"TEBIBYTE":"BINARY",
	"TERA_BINARY_BYTE":"BINARY",
	"TERABYTE_SI":"BINARY",
	"PETABYTE":"BINARY",
	"PEBIBYTE":"BINARY",
	"PETA_BINARY_BYTE":"BINARY",
	"PETABYTE_SI":"BINARY",
	"EXABYTE":"BINARY",
	"EXBIBYTE":"BINARY",
	"EXA_BINARY_BYTE":"BINARY",
	"EXABYTE_SI":"BINARY",
	"ZETTABYTE":"BINARY",
	"ZEBIBYTE":"BINARY",
	"ZETTA_BINARY_BYTE":"BINARY",
	"ZETTABYTE_SI":"BINARY",
	"YOTTABYTE":"BINARY",
	"YOBIBYTE":"BINARY",
	"YOTTA_BINARY_BYTE":"BINARY",
	"YOTTABYTE_SI":"BINARY",
	"ABFARAD":"CAPACITANCE",
	"AMPERE_PER_SECOND_VOLT":"CAPACITANCE",
	"CENTIFARAD":"CAPACITANCE",
	"COULOMB_PER_VOLT":"CAPACITANCE",
	"DECIFARAD":"CAPACITANCE",
	"DEKAFARAD":"CAPACITANCE",
	"ELECTROMAGNETIC_UNIT":"CAPACITANCE",
	"ELECTROSTATIC_UNIT":"CAPACITANCE",
	"FARAD":"CAPACITANCE",
	"FARAD_INTERNATIONAL":"CAPACITANCE",
	"GAUSSIAN":"CAPACITANCE",
	"GIGAFARAD":"CAPACITANCE",
	"HECTOFARAD":"CAPACITANCE",
	"JAR":"CAPACITANCE",
	"KILOFARAD":"CAPACITANCE",
	"MEGAFARAD":"CAPACITANCE",
	"MICROFARAD":"CAPACITANCE",
	"MILLIFARAD":"CAPACITANCE",
	"NANOFARAD":"CAPACITANCE",
	"PICOFARAD":"CAPACITANCE",
	"PUFF":"CAPACITANCE",
	"SECOND_PER_OHM":"CAPACITANCE",
	"STATFARAD":"CAPACITANCE",
	"TERAFARAD":"CAPACITANCE",
	"ALUMINIUM":"DENSITY",
	"COPPER":"DENSITY",
	"GOLD":"DENSITY",
	"GRAIN_PER_CUBIC_FOOT":"DENSITY",
	"GRAIN_PER_CUBIC_INCH":"DENSITY",
	"GRAIN_PER_CUBIC_YARD":"DENSITY",
	"GRAIN_PER_GALLON":"DENSITY",
	"GRAIN_PER_GALLON_US":"DENSITY",
	"GRAM_PER_CUBIC_CENTIMETER":"DENSITY",
	"GRAM_PER_CUBIC_DECIMETER":"DENSITY",
	"GRAM_PER_CUBIC_METER":"DENSITY",
	"GRAM_PER_LITER":"DENSITY",
	"GRAM_PER_MILLILITER":"DENSITY",
	"KILOGRAM_PER_CUBIC_CENTIMETER":"DENSITY",
	"KILOGRAM_PER_CUBIC_DECIMETER":"DENSITY",
	"KILOGRAM_PER_CUBIC_METER":"DENSITY",
	"KILOGRAM_PER_CUBIC_MILLIMETER":"DENSITY",
	"KILOGRAM_PER_LITER":"DENSITY",
	"KILOGRAM_PER_MILLILITER":"DENSITY",
	"LEAD":"DENSITY",
	"MEGAGRAM_PER_CUBIC_CENTIMETER":"DENSITY",
	"MEGAGRAM_PER_CUBIC_DECIMETER":"DENSITY",
	"MEGAGRAM_PER_CUBIC_METER":"DENSITY",
	"MEGAGRAM_PER_LITER":"DENSITY",
	"MEGAGRAM_PER_MILLILITER":"DENSITY",
	"MICROGRAM_PER_CUBIC_CENTIMETER":"DENSITY",
	"MICROGRAM_PER_CUBIC_DECIMETER":"DENSITY",
	"MICROGRAM_PER_CUBIC_METER":"DENSITY",
	"MICROGRAM_PER_LITER":"DENSITY",
	"MICROGRAM_PER_MILLILITER":"DENSITY",
	"MILLIGRAM_PER_CUBIC_CENTIMETER":"DENSITY",
	"MILLIGRAM_PER_CUBIC_DECIMETER":"DENSITY",
	"MILLIGRAM_PER_CUBIC_METER":"DENSITY",
	"MILLIGRAM_PER_LITER":"DENSITY",
	"MILLIGRAM_PER_MILLILITER":"DENSITY",
	"OUNCE_PER_CUBIC_FOOT":"DENSITY",
	"OUNCE_PER_CUBIC_FOOT_TROY":"DENSITY",
	"OUNCE_PER_CUBIC_INCH":"DENSITY",
	"OUNCE_PER_CUBIC_INCH_TROY":"DENSITY",
	"OUNCE_PER_CUBIC_YARD":"DENSITY",
	"OUNCE_PER_CUBIC_YARD_TROY":"DENSITY",
	"OUNCE_PER_GALLON":"DENSITY",
	"OUNCE_PER_GALLON_US":"DENSITY",
	"OUNCE_PER_GALLON_TROY":"DENSITY",
	"OUNCE_PER_GALLON_US_TROY":"DENSITY",
	"POUND_PER_CIRCULAR_MIL_FOOT":"DENSITY",
	"POUND_PER_CUBIC_FOOT":"DENSITY",
	"POUND_PER_CUBIC_INCH":"DENSITY",
	"POUND_PER_CUBIC_YARD":"DENSITY",
	"POUND_PER_GALLON":"DENSITY",
	"POUND_PER_KILOGALLON":"DENSITY",
	"POUND_PER_MEGAGALLON":"DENSITY",
	"POUND_PER_GALLON_US":"DENSITY",
	"POUND_PER_KILOGALLON_US":"DENSITY",
	"POUND_PER_MEGAGALLON_US":"DENSITY",
	"SILVER":"DENSITY",
	"SLUG_PER_CUBIC_FOOT":"DENSITY",
	"SLUG_PER_CUBIC_INCH":"DENSITY",
	"SLUG_PER_CUBIC_YARD":"DENSITY",
	"SLUG_PER_GALLON":"DENSITY",
	"SLUG_PER_GALLON_US":"DENSITY",
	"TON_PER_CUBIC_FOOT_LONG":"DENSITY",
	"TON_PER_CUBIC_FOOT":"DENSITY",
	"TON_PER_CUBIC_INCH_LONG":"DENSITY",
	"TON_PER_CUBIC_INCH":"DENSITY",
	"TON_PER_CUBIC_YARD_LONG":"DENSITY",
	"TON_PER_CUBIC_YARD":"DENSITY",
	"TON_PER_GALLON_LONG":"DENSITY",
	"TON_PER_GALLON_US_LONG":"DENSITY",
	"TON_PER_GALLON":"DENSITY",
	"TON_PER_GALLON_US":"DENSITY",
	"TONNE_PER_CUBIC_CENTIMETER":"DENSITY",
	"TONNE_PER_CUBIC_DECIMETER":"DENSITY",
	"TONNE_PER_CUBIC_METER":"DENSITY",
	"TONNE_PER_LITER":"DENSITY",
	"TONNE_PER_MILLILITER":"DENSITY",
	"WATER":"DENSITY",
	"ONE_PER_SECOND":"FREQUENCY",
	"CYCLE_PER_SECOND":"FREQUENCY",
	"DEGREE_PER_HOUR":"FREQUENCY",
	"DEGREE_PER_MINUTE":"FREQUENCY",
	"DEGREE_PER_SECOND":"FREQUENCY",
	"GIGAHERTZ":"FREQUENCY",
	"HERTZ":"FREQUENCY",
	"KILOHERTZ":"FREQUENCY",
	"MEGAHERTZ":"FREQUENCY",
	"MILLIHERTZ":"FREQUENCY",
	"RADIAN_PER_HOUR":"FREQUENCY",
	"RADIAN_PER_MINUTE":"FREQUENCY",
	"RADIAN_PER_SECOND":"FREQUENCY",
	"REVOLUTION_PER_HOUR":"FREQUENCY",
	"REVOLUTION_PER_MINUTE":"FREQUENCY",
	"REVOLUTION_PER_SECOND":"FREQUENCY",
	"RPM":"FREQUENCY",
	"TERRAHERTZ":"FREQUENCY",
	"FOOTCANDLE":"ILLUMINATION",
	"KILOLUX":"ILLUMINATION",
	"LUMEN_PER_SQUARE_CENTIMETER":"ILLUMINATION",
	"LUMEN_PER_SQUARE_FOOT":"ILLUMINATION",
	"LUMEN_PER_SQUARE_INCH":"ILLUMINATION",
	"LUMEN_PER_SQUARE_METER":"ILLUMINATION",
	"LUX":"ILLUMINATION",
	"METERCANDLE":"ILLUMINATION",
	"MILLIPHOT":"ILLUMINATION",
	"NOX":"ILLUMINATION",
	"PHOT":"ILLUMINATION",
	"AGATE":"LENGTH",
	"ALEN_DANISH":"LENGTH",
	"ALEN":"LENGTH",
	"ALEN_SWEDISH":"LENGTH",
	"ANGSTROM":"LENGTH",
	"ARMS":"LENGTH",
	"ARPENT_CANADIAN":"LENGTH",
	"ARPENT":"LENGTH",
	"ARSHEEN":"LENGTH",
	"ARSHIN":"LENGTH",
	"ARSHIN_IRAQ":"LENGTH",
	"ASTRONOMICAL_UNIT":"LENGTH",
	"ATTOMETER":"LENGTH",
	"BAMBOO":"LENGTH",
	"BARLEYCORN":"LENGTH",
	"BEE_SPACE":"LENGTH",
	"BICRON":"LENGTH",
	"BLOCK_US_EAST":"LENGTH",
	"BLOCK_US_WEST":"LENGTH",
	"BLOCK_US_SOUTH":"LENGTH",
	"BOHR":"LENGTH",
	"BRACCIO":"LENGTH",
	"BRAZA_ARGENTINA":"LENGTH",
	"BRAZA":"LENGTH",
	"BRAZA_US":"LENGTH",
	"BUTTON":"LENGTH",
	"CABLE_US":"LENGTH",
	"CABLE_UK":"LENGTH",
	"CALIBER":"LENGTH",
	"CANA":"LENGTH",
	"CAPE_FOOT":"LENGTH",
	"CAPE_INCH":"LENGTH",
	"CAPE_ROOD":"LENGTH",
	"CENTIMETER":"LENGTH",
	"CHAIN":"LENGTH",
	"CHAIN_ENGINEER":"LENGTH",
	"CHIH":"LENGTH",
	"CHINESE_FOOT":"LENGTH",
	"CHINESE_INCH":"LENGTH",
	"CHINESE_MILE":"LENGTH",
	"CHINESE_YARD":"LENGTH",
	"CITY_BLOCK_US_EAST":"LENGTH",
	"CITY_BLOCK_US_WEST":"LENGTH",
	"CITY_BLOCK_US_SOUTH":"LENGTH",
	"CLICK":"LENGTH",
	"CUADRA":"LENGTH",
	"CUADRA_ARGENTINA":"LENGTH",
	"Length:CUBIT_EGYPT":"LENGTH",
	"CUBIT_ROYAL":"LENGTH",
	"CUBIT_UK":"LENGTH",
	"CUBIT":"LENGTH",
	"CUERDA":"LENGTH",
	"DECIMETER":"LENGTH",
	"DEKAMETER":"LENGTH",
	"DIDOT_POINT":"LENGTH",
	"DIGIT":"LENGTH",
	"DIRAA":"LENGTH",
	"DONG":"LENGTH",
	"DOUZIEME_WATCH":"LENGTH",
	"DOUZIEME":"LENGTH",
	"DRA_IRAQ":"LENGTH",
	"DRA":"LENGTH",
	"EL":"LENGTH",
	"ELL":"LENGTH",
	"ELL_SCOTTISH":"LENGTH",
	"ELLE":"LENGTH",
	"ELLE_VIENNA":"LENGTH",
	"EM":"LENGTH",
	"ESTADIO_PORTUGAL":"LENGTH",
	"ESTADIO":"LENGTH",
	"EXAMETER":"LENGTH",
	"FADEN_AUSTRIA":"LENGTH",
	"FADEN":"LENGTH",
	"FALL":"LENGTH",
	"FALL_SCOTTISH":"LENGTH",
	"FATHOM":"LENGTH",
	"FATHOM_ANCIENT":"LENGTH",
	"FAUST":"LENGTH",
	"FEET_OLD_CANADIAN":"LENGTH",
	"FEET_EGYPT":"LENGTH",
	"FEET_FRANCE":"LENGTH",
	"FEET":"LENGTH",
	"FEET_IRAQ":"LENGTH",
	"FEET_NETHERLAND":"LENGTH",
	"FEET_ITALIC":"LENGTH",
	"FEET_SURVEY":"LENGTH",
	"FEMTOMETER":"LENGTH",
	"FERMI":"LENGTH",
	"FINGER":"LENGTH",
	"FINGERBREADTH":"LENGTH",
	"FIST":"LENGTH",
	"FOD":"LENGTH",
	"FOOT_EGYPT":"LENGTH",
	"FOOT_FRANCE":"LENGTH",
	"FOOT":"LENGTH",
	"FOOT_IRAQ":"LENGTH",
	"FOOT_NETHERLAND":"LENGTH",
	"FOOT_ITALIC":"LENGTH",
	"FOOT_SURVEY":"LENGTH",
	"FOOTBALL_FIELD_CANADA":"LENGTH",
	"FOOTBALL_FIELD_US":"LENGTH",
	"FOOTBALL_FIELD":"LENGTH",
	"FURLONG":"LENGTH",
	"FURLONG_SURVEY":"LENGTH",
	"FUSS":"LENGTH",
	"GIGAMETER":"LENGTH",
	"GIGAPARSEC":"LENGTH",
	"GNATS_EYE":"LENGTH",
	"GOAD":"LENGTH",
	"GRY":"LENGTH",
	"HAIRS_BREADTH":"LENGTH",
	"HAND":"LENGTH",
	"HANDBREADTH":"LENGTH",
	"HAT":"LENGTH",
	"HECTOMETER":"LENGTH",
	"HEER":"LENGTH",
	"HIRO":"LENGTH",
	"HUBBLE":"LENGTH",
	"HVAT":"LENGTH",
	"INCH":"LENGTH",
	"KEN":"LENGTH",
	"KERAT":"LENGTH",
	"KILOFOOT":"LENGTH",
	"KILOMETER":"LENGTH",
	"KILOPARSEC":"LENGTH",
	"KILOYARD":"LENGTH",
	"KIND":"LENGTH",
	"KLAFTER":"LENGTH",
	"KLAFTER_SWISS":"LENGTH",
	"KLICK":"LENGTH",
	"KYU":"LENGTH",
	"LAP_ANCIENT":"LENGTH",
	"LAP":"LENGTH",
	"LAP_POOL":"LENGTH",
	"LEAGUE_ANCIENT":"LENGTH",
	"LEAGUE_NAUTIC":"LENGTH",
	"LEAGUE_UK_NAUTIC":"LENGTH",
	"LEAGUE":"LENGTH",
	"LEAGUE_US":"LENGTH",
	"LEAP":"LENGTH",
	"LEGOA":"LENGTH",
	"LEGUA":"LENGTH",
	"LEGUA_US":"LENGTH",
	"LEGUA_SPAIN_OLD":"LENGTH",
	"LEGUA_SPAIN":"LENGTH",
	"LI_ANCIENT":"LENGTH",
	"LI_IMPERIAL":"LENGTH",
	"LI":"LENGTH",
	"LIEUE":"LENGTH",
	"LIEUE_METRIC":"LENGTH",
	"LIEUE_NAUTIC":"LENGTH",
	"LIGHT_SECOND":"LENGTH",
	"LIGHT_MINUTE":"LENGTH",
	"LIGHT_HOUR":"LENGTH",
	"LIGHT_DAY":"LENGTH",
	"LIGHT_YEAR":"LENGTH",
	"LIGNE":"LENGTH",
	"LIGNE_SWISS":"LENGTH",
	"LINE":"LENGTH",
	"LINE_SMALL":"LENGTH",
	"LINK":"LENGTH",
	"LINK_ENGINEER":"LENGTH",
	"LUG":"LENGTH",
	"LUG_GREAT":"LENGTH",
	"MARATHON":"LENGTH",
	"MARK_TWAIN":"LENGTH",
	"MEGAMETER":"LENGTH",
	"MEGAPARSEC":"LENGTH",
	"MEILE_AUSTRIAN":"LENGTH",
	"MEILE":"LENGTH",
	"MEILE_GERMAN":"LENGTH",
	"METER":"LENGTH",
	"METRE":"LENGTH",
	"METRIC_MILE":"LENGTH",
	"METRIC_MILE_US":"LENGTH",
	"MICROINCH":"LENGTH",
	"MICROMETER":"LENGTH",
	"MICROMICRON":"LENGTH",
	"MICRON":"LENGTH",
	"MIGLIO":"LENGTH",
	"MIIL":"LENGTH",
	"MIIL_DENMARK":"LENGTH",
	"MIIL_SWEDISH":"LENGTH",
	"MIL":"LENGTH",
	"MIL_SWEDISH":"LENGTH",
	"MILE_UK":"LENGTH",
	"MILE_IRISH":"LENGTH",
	"MILE":"LENGTH",
	"MILE_NAUTIC":"LENGTH",
	"MILE_NAUTIC_UK":"LENGTH",
	"MILE_NAUTIC_US":"LENGTH",
	"MILE_ANCIENT":"LENGTH",
	"MILE_SCOTTISH":"LENGTH",
	"MILE_STATUTE":"LENGTH",
	"MILE_US":"LENGTH",
	"MILHA":"LENGTH",
	"MILITARY_PACE":"LENGTH",
	"MILITARY_PACE_DOUBLE":"LENGTH",
	"MILLA":"LENGTH",
	"MILLE":"LENGTH",
	"MILLIARE":"LENGTH",
	"MILLIMETER":"LENGTH",
	"MILLIMICRON":"LENGTH",
	"MKONO":"LENGTH",
	"MOOT":"LENGTH",
	"MYRIAMETER":"LENGTH",
	"NAIL":"LENGTH",
	"NANOMETER":"LENGTH",
	"NANON":"LENGTH",
	"PACE":"LENGTH",
	"PACE_ROMAN":"LENGTH",
	"PALM_DUTCH":"LENGTH",
	"PALM_UK":"LENGTH",
	"PALM":"LENGTH",
	"PALMO_PORTUGUESE":"LENGTH",
	"PALMO":"LENGTH",
	"PALMO_US":"LENGTH",
	"PARASANG":"LENGTH",
	"PARIS_FOOT":"LENGTH",
	"PARSEC":"LENGTH",
	"PE":"LENGTH",
	"PEARL":"LENGTH",
	"PERCH":"LENGTH",
	"PERCH_IRELAND":"LENGTH",
	"PERTICA":"LENGTH",
	"PES":"LENGTH",
	"PETAMETER":"LENGTH",
	"PICA":"LENGTH",
	"PICOMETER":"LENGTH",
	"PIE_ARGENTINA":"LENGTH",
	"PIE_ITALIC":"LENGTH",
	"PIE":"LENGTH",
	"PIE_US":"LENGTH",
	"PIED_DE_ROI":"LENGTH",
	"PIK":"LENGTH",
	"PIKE":"LENGTH",
	"POINT_ADOBE":"LENGTH",
	"POINT_DIDOT":"LENGTH",
	"POINT_TEX":"LENGTH",
	"POLE":"LENGTH",
	"POLEGADA":"LENGTH",
	"POUCE":"LENGTH",
	"PU":"LENGTH",
	"PULGADA":"LENGTH",
	"PYGME":"LENGTH",
	"Q":"LENGTH",
	"QUADRANT":"LENGTH",
	"QUARTER_CLOTH":"LENGTH",
	"QUARTER_PRINT":"LENGTH",
	"RANGE":"LENGTH",
	"REED":"LENGTH",
	"RI":"LENGTH",
	"RIDGE":"LENGTH",
	"RIVER":"LENGTH",
	"ROD":"LENGTH",
	"ROD_SURVEY":"LENGTH",
	"ROEDE":"LENGTH",
	"ROOD":"LENGTH",
	"ROPE":"LENGTH",
	"ROYAL_FOOT":"LENGTH",
	"RUTE":"LENGTH",
	"SADZHEN":"LENGTH",
	"SAGENE":"LENGTH",
	"SCOTS_FOOT":"LENGTH",
	"SCOTS_MILE":"LENGTH",
	"SEEMEILE":"LENGTH",
	"SHACKLE":"LENGTH",
	"SHAFTMENT":"LENGTH",
	"SHAFTMENT_ANCIENT":"LENGTH",
	"SHAKU":"LENGTH",
	"SIRIOMETER":"LENGTH",
	"SMOOT":"LENGTH",
	"SPAN":"LENGTH",
	"SPAT":"LENGTH",
	"STADIUM":"LENGTH",
	"STEP":"LENGTH",
	"STICK":"LENGTH",
	"STORY":"LENGTH",
	"STRIDE":"LENGTH",
	"STRIDE_ROMAN":"LENGTH",
	"TENTHMETER":"LENGTH",
	"TERAMETER":"LENGTH",
	"THOU":"LENGTH",
	"TOISE":"LENGTH",
	"TOWNSHIP":"LENGTH",
	"T_SUN":"LENGTH",
	"TU":"LENGTH",
	"TWAIN":"LENGTH",
	"TWIP":"LENGTH",
	"U":"LENGTH",
	"VARA_CALIFORNIA":"LENGTH",
	"VARA_MEXICAN":"LENGTH",
	"VARA_PORTUGUESE":"LENGTH",
	"VARA_AMERICA":"LENGTH",
	"VARA":"LENGTH",
	"VARA_TEXAS":"LENGTH",
	"VERGE":"LENGTH",
	"VERSHOK":"LENGTH",
	"VERST":"LENGTH",
	"WAH":"LENGTH",
	"WERST":"LENGTH",
	"X_UNIT":"LENGTH",
	"YOCTOMETER":"LENGTH",
	"YOTTAMETER":"LENGTH",
	"ZEPTOMETER":"LENGTH",
	"ZETTAMETER":"LENGTH",
	"ZOLL":"LENGTH",
	"ZOLL_SWISS":"LENGTH",
	"APOSTILB":"LIGHTNESS",
	"BLONDEL":"LIGHTNESS",
	"CANDELA_PER_SQUARE_CENTIMETER":"LIGHTNESS",
	"CANDELA_PER_SQUARE_FOOT":"LIGHTNESS",
	"CANDELA_PER_SQUARE_INCH":"LIGHTNESS",
	"CANDELA_PER_SQUARE_METER":"LIGHTNESS",
	"FOOTLAMBERT":"LIGHTNESS",
	"KILOCANDELA_PER_SQUARE_CENTIMETER":"LIGHTNESS",
	"KILOCANDELA_PER_SQUARE_FOOT":"LIGHTNESS",
	"KILOCANDELA_PER_SQUARE_INCH":"LIGHTNESS",
	"KILOCANDELA_PER_SQUARE_METER":"LIGHTNESS",
	"LAMBERT":"LIGHTNESS",
	"MILLILAMBERT":"LIGHTNESS",
	"NIT":"LIGHTNESS",
	"STILB":"LIGHTNESS",
	"BINARY":"NUMBER",
	"TERNARY":"NUMBER",
	"QUATERNARY":"NUMBER",
	"QUINARY":"NUMBER",
	"SENARY":"NUMBER",
	"SEPTENARY":"NUMBER",
	"OCTAL":"NUMBER",
	"NONARY":"NUMBER",
	"DECIMAL":"NUMBER",
	"DUODECIMAL":"NUMBER",
	"HEXADECIMAL":"NUMBER",
	"ROMAN":"NUMBER",
	"ATTOWATT":"POWER",
	"BTU_PER_HOUR":"POWER",
	"BTU_PER_MINUTE":"POWER",
	"BTU_PER_SECOND":"POWER",
	"CALORIE_PER_HOUR":"POWER",
	"CALORIE_PER_MINUTE":"POWER",
	"CALORIE_PER_SECOND":"POWER",
	"CENTIWATT":"POWER",
	"CHEVAL_VAPEUR":"POWER",
	"CLUSEC":"POWER",
	"DECIWATT":"POWER",
	"DEKAWATT":"POWER",
	"DYNE_CENTIMETER_PER_HOUR":"POWER",
	"DYNE_CENTIMETER_PER_MINUTE":"POWER",
	"DYNE_CENTIMETER_PER_SECOND":"POWER",
	"ERG_PER_HOUR":"POWER",
	"ERG_PER_MINUTE":"POWER",
	"ERG_PER_SECOND":"POWER",
	"EXAWATT":"POWER",
	"FEMTOWATT":"POWER",
	"FOOT_POUND_FORCE_PER_HOUR":"POWER",
	"FOOT_POUND_FORCE_PER_MINUTE":"POWER",
	"FOOT_POUND_FORCE_PER_SECOND":"POWER",
	"FOOT_POUNDAL_PER_HOUR":"POWER",
	"FOOT_POUNDAL_PER_MINUTE":"POWER",
	"FOOT_POUNDAL_PER_SECOND":"POWER",
	"GIGAWATT":"POWER",
	"GRAM_FORCE_CENTIMETER_PER_HOUR":"POWER",
	"GRAM_FORCE_CENTIMETER_PER_MINUTE":"POWER",
	"GRAM_FORCE_CENTIMETER_PER_SECOND":"POWER",
	"HECTOWATT":"POWER",
	"HORSEPOWER_INTERNATIONAL":"POWER",
	"HORSEPOWER_ELECTRIC":"POWER",
	"HORSEPOWER":"POWER",
	"HORSEPOWER_WATER":"POWER",
	"INCH_OUNCH_FORCE_REVOLUTION_PER_MINUTE":"POWER",
	"JOULE_PER_HOUR":"POWER",
	"JOULE_PER_MINUTE":"POWER",
	"JOULE_PER_SECOND":"POWER",
	"KILOCALORIE_PER_HOUR":"POWER",
	"KILOCALORIE_PER_MINUTE":"POWER",
	"KILOCALORIE_PER_SECOND":"POWER",
	"KILOGRAM_FORCE_METER_PER_HOUR":"POWER",
	"KILOGRAM_FORCE_METER_PER_MINUTE":"POWER",
	"KILOGRAM_FORCE_METER_PER_SECOND":"POWER",
	"KILOPOND_METER_PER_HOUR":"POWER",
	"KILOPOND_METER_PER_MINUTE":"POWER",
	"KILOPOND_METER_PER_SECOND":"POWER",
	"KILOWATT":"POWER",
	"MEGAWATT":"POWER",
	"MICROWATT":"POWER",
	"MILLION_BTU_PER_HOUR":"POWER",
	"MILLIWATT":"POWER",
	"NANOWATT":"POWER",
	"NEWTON_METER_PER_HOUR":"POWER",
	"NEWTON_METER_PER_MINUTE":"POWER",
	"NEWTON_METER_PER_SECOND":"POWER",
	"PETAWATT":"POWER",
	"PFERDESTAERKE":"POWER",
	"PICOWATT":"POWER",
	"PONCELET":"POWER",
	"POUND_SQUARE_FOOT_PER_CUBIC_SECOND":"POWER",
	"TERAWATT":"POWER",
	"TON_OF_REFRIGERATION":"POWER",
	"WATT":"POWER",
	"YOCTOWATT":"POWER",
	"YOTTAWATT":"POWER",
	"ZEPTOWATT":"POWER",
	"ZETTAWATT":"POWER",
	"ATMOSPHERE":"PRESSURE",
	"ATMOSPHERE_TECHNICAL":"PRESSURE",
	"ATTOBAR":"PRESSURE",
	"ATTOPASCAL":"PRESSURE",
	"BAR":"PRESSURE",
	"BARAD":"PRESSURE",
	"BARYE":"PRESSURE",
	"CENTIBAR":"PRESSURE",
	"CENTIHG":"PRESSURE",
	"CENTIMETER_MERCURY_0C":"PRESSURE",
	"CENTIMETER_WATER_4C":"PRESSURE",
	"CENTIPASCAL":"PRESSURE",
	"CENTITORR":"PRESSURE",
	"DECIBAR":"PRESSURE",
	"DECIPASCAL":"PRESSURE",
	"DECITORR":"PRESSURE",
	"DEKABAR":"PRESSURE",
	"DEKAPASCAL":"PRESSURE",
	"DYNE_PER_SQUARE_CENTIMETER":"PRESSURE",
	"EXABAR":"PRESSURE",
	"EXAPASCAL":"PRESSURE",
	"FEMTOBAR":"PRESSURE",
	"FEMTOPASCAL":"PRESSURE",
	"FOOT_AIR_0C":"PRESSURE",
	"FOOT_AIR_15C":"PRESSURE",
	"FOOT_HEAD":"PRESSURE",
	"FOOT_MERCURY_0C":"PRESSURE",
	"FOOT_WATER_4C":"PRESSURE",
	"GIGABAR":"PRESSURE",
	"GIGAPASCAL":"PRESSURE",
	"GRAM_FORCE_SQUARE_CENTIMETER":"PRESSURE",
	"HECTOBAR":"PRESSURE",
	"HECTOPASCAL":"PRESSURE",
	"INCH_AIR_0C":"PRESSURE",
	"INCH_AIR_15C":"PRESSURE",
	"INCH_MERCURY_0C":"PRESSURE",
	"INCH_WATER_4C":"PRESSURE",
	"KILOBAR":"PRESSURE",
	"KILOGRAM_FORCE_PER_SQUARE_CENTIMETER":"PRESSURE",
	"KILOGRAM_FORCE_PER_SQUARE_METER":"PRESSURE",
	"KILOGRAM_FORCE_PER_SQUARE_MILLIMETER":"PRESSURE",
	"KILONEWTON_PER_SQUARE_METER":"PRESSURE",
	"KILOPASCAL":"PRESSURE",
	"KILOPOND_PER_SQUARE_CENTIMETER":"PRESSURE",
	"KILOPOND_PER_SQUARE_METER":"PRESSURE",
	"KILOPOND_PER_SQUARE_MILLIMETER":"PRESSURE",
	"KIP_PER_SQUARE_FOOT":"PRESSURE",
	"KIP_PER_SQUARE_INCH":"PRESSURE",
	"MEGABAR":"PRESSURE",
	"MEGANEWTON_PER_SQUARE_METER":"PRESSURE",
	"MEGAPASCAL":"PRESSURE",
	"METER_AIR_0C":"PRESSURE",
	"METER_AIR_15C":"PRESSURE",
	"METER_HEAD":"PRESSURE",
	"MICROBAR":"PRESSURE",
	"MICROMETER_MERCURY_0C":"PRESSURE",
	"MICROMETER_WATER_4C":"PRESSURE",
	"MICRON_MERCURY_0C":"PRESSURE",
	"MICROPASCAL":"PRESSURE",
	"MILLIBAR":"PRESSURE",
	"MILLIHG":"PRESSURE",
	"MILLIMETER_MERCURY_0C":"PRESSURE",
	"MILLIMETER_WATER_4C":"PRESSURE",
	"MILLIPASCAL":"PRESSURE",
	"MILLITORR":"PRESSURE",
	"NANOBAR":"PRESSURE",
	"NANOPASCAL":"PRESSURE",
	"NEWTON_PER_SQUARE_METER":"PRESSURE",
	"NEWTON_PER_SQUARE_MILLIMETER":"PRESSURE",
	"OUNCE_PER_SQUARE_INCH":"PRESSURE",
	"PASCAL":"PRESSURE",
	"PETABAR":"PRESSURE",
	"PETAPASCAL":"PRESSURE",
	"PICOBAR":"PRESSURE",
	"PICOPASCAL":"PRESSURE",
	"PIEZE":"PRESSURE",
	"POUND_PER_SQUARE_FOOT":"PRESSURE",
	"POUND_PER_SQUARE_INCH":"PRESSURE",
	"POUNDAL_PER_SQUARE_FOOT":"PRESSURE",
	"STHENE_PER_SQUARE_METER":"PRESSURE",
	"TECHNICAL_ATMOSPHERE":"PRESSURE",
	"TERABAR":"PRESSURE",
	"TERAPASCAL":"PRESSURE",
	"TON_PER_SQUARE_FOOT":"PRESSURE",
	"TON_PER_SQUARE_FOOT_SHORT":"PRESSURE",
	"TON_PER_SQUARE_INCH":"PRESSURE",
	"TON_PER_SQUARE_INCH_SHORT":"PRESSURE",
	"TON_PER_SQUARE_METER":"PRESSURE",
	"TORR":"PRESSURE",
	"WATER_COLUMN_CENTIMETER":"PRESSURE",
	"WATER_COLUMN_INCH":"PRESSURE",
	"WATER_COLUMN_MILLIMETER":"PRESSURE",
	"YOCTOBAR":"PRESSURE",
	"YOCTOPASCAL":"PRESSURE",
	"YOTTABAR":"PRESSURE",
	"YOTTAPASCAL":"PRESSURE",
	"ZEPTOBAR":"PRESSURE",
	"ZEPTOPASCAL":"PRESSURE",
	"ZETTABAR":"PRESSURE",
	"ZETTAPASCAL":"PRESSURE",
	"BENZ":"SPEED",
	"CENTIMETER_PER_DAY":"SPEED",
	"CENTIMETER_PER_HOUR":"SPEED",
	"CENTIMETER_PER_MINUTE":"SPEED",
	"CENTIMETER_PER_SECOND":"SPEED",
	"DEKAMETER_PER_DAY":"SPEED",
	"DEKAMETER_PER_HOUR":"SPEED",
	"DEKAMETER_PER_MINUTE":"SPEED",
	"DEKAMETER_PER_SECOND":"SPEED",
	"FOOT_PER_DAY":"SPEED",
	"FOOT_PER_HOUR":"SPEED",
	"FOOT_PER_MINUTE":"SPEED",
	"FOOT_PER_SECOND":"SPEED",
	"FURLONG_PER_DAY":"SPEED",
	"FURLONG_PER_FORTNIGHT":"SPEED",
	"FURLONG_PER_HOUR":"SPEED",
	"FURLONG_PER_MINUTE":"SPEED",
	"FURLONG_PER_SECOND":"SPEED",
	"HECTOMETER_PER_DAY":"SPEED",
	"HECTOMETER_PER_HOUR":"SPEED",
	"HECTOMETER_PER_MINUTE":"SPEED",
	"HECTOMETER_PER_SECOND":"SPEED",
	"INCH_PER_DAY":"SPEED",
	"INCH_PER_HOUR":"SPEED",
	"INCH_PER_MINUTE":"SPEED",
	"INCH_PER_SECOND":"SPEED",
	"KILOMETER_PER_DAY":"SPEED",
	"KILOMETER_PER_HOUR":"SPEED",
	"KILOMETER_PER_MINUTE":"SPEED",
	"KILOMETER_PER_SECOND":"SPEED",
	"KNOT":"SPEED",
	"LEAGUE_PER_DAY":"SPEED",
	"LEAGUE_PER_HOUR":"SPEED",
	"LEAGUE_PER_MINUTE":"SPEED",
	"LEAGUE_PER_SECOND":"SPEED",
	"MACH":"SPEED",
	"MEGAMETER_PER_DAY":"SPEED",
	"MEGAMETER_PER_HOUR":"SPEED",
	"MEGAMETER_PER_MINUTE":"SPEED",
	"MEGAMETER_PER_SECOND":"SPEED",
	"METER_PER_DAY":"SPEED",
	"METER_PER_HOUR":"SPEED",
	"METER_PER_MINUTE":"SPEED",
	"METER_PER_SECOND":"SPEED",
	"MILE_PER_DAY":"SPEED",
	"MILE_PER_HOUR":"SPEED",
	"MILE_PER_MINUTE":"SPEED",
	"MILE_PER_SECOND":"SPEED",
	"MILLIMETER_PER_DAY":"SPEED",
	"MILLIMETER_PER_HOUR":"SPEED",
	"MILLIMETER_PER_MINUTE":"SPEED",
	"MILLIMETER_PER_SECOND":"SPEED",
	"MILLIMETER_PER_MICROSECOND":"SPEED",
	"MILLIMETER_PER_100_MICROSECOND":"SPEED",
	"NAUTIC_MILE_PER_DAY":"SPEED",
	"NAUTIC_MILE_PER_HOUR":"SPEED",
	"NAUTIC_MILE_PER_MINUTE":"SPEED",
	"NAUTIC_MILE_PER_SECOND":"SPEED",
	"LIGHTSPEED_AIR":"SPEED",
	"LIGHTSPEED_GLASS":"SPEED",
	"LIGHTSPEED_ICE":"SPEED",
	"LIGHTSPEED_VACUUM":"SPEED",
	"LIGHTSPEED_WATER":"SPEED",
	"SOUNDSPEED_AIT":"SPEED",
	"SOUNDSPEED_METAL":"SPEED",
	"SOUNDSPEED_WATER":"SPEED",
	"YARD_PER_DAY":"SPEED",
	"YARD_PER_HOUR":"SPEED",
	"YARD_PER_MINUTE":"SPEED",
	"YARD_PER_SECOND":"SPEED",
	"CELSIUS":"TEMPERATURE",
	"FAHRENHEIT":"TEMPERATURE",
	"RANKINE":"TEMPERATURE",
	"REAUMUR":"TEMPERATURE",
	"KELVIN":"TEMPERATURE",
	"ANOMALISTIC_YEAR":"TIME",
	"ATTOSECOND":"TIME",
	"CENTURY":"TIME",
	"DAY":"TIME",
	"DECADE":"TIME",
	"DRACONIC_YEAR":"TIME",
	"EXASECOND":"TIME",
	"FEMTOSECOND":"TIME",
	"FORTNIGHT":"TIME",
	"GAUSSIAN_YEAR":"TIME",
	"GIGASECOND":"TIME",
	"GREAT_YEAR":"TIME",
	"GREGORIAN_YEAR":"TIME",
	"HOUR":"TIME",
	"JULIAN_YEAR":"TIME",
	"KILOSECOND":"TIME",
	"LEAPYEAR":"TIME",
	"MEGASECOND":"TIME",
	"MICROSECOND":"TIME",
	"MILLENIUM":"TIME",
	"MILLISECOND":"TIME",
	"MINUTE":"TIME",
	"MONTH":"TIME",
	"NANOSECOND":"TIME",
	"PETASECOND":"TIME",
	"PICOSECOND":"TIME",
	"SECOND":"TIME",
	"SHAKE":"TIME",
	"SIDEREAL_YEAR":"TIME",
	"TERASECOND":"TIME",
	"TROPICAL_YEAR":"TIME",
	"WEEK":"TIME",
	"YEAR":"TIME",
	"ACRE_FOOT":"VOLUME",
	"ACRE_FOOT_SURVEY":"VOLUME",
	"ACRE_INCH":"VOLUME",
	"BARREL_WINE":"VOLUME",
	"BARREL":"VOLUME",
	"BARREL_US_DRY":"VOLUME",
	"BARREL_US_FEDERAL":"VOLUME",
	"BARREL_US":"VOLUME",
	"BARREL_US_PETROLEUM":"VOLUME",
	"BOARD_FOOT":"VOLUME",
	"BUCKET":"VOLUME",
	"BUCKET_US":"VOLUME",
	"BUSHEL":"VOLUME",
	"BUSHEL_US":"VOLUME",
	"CENTILITER":"VOLUME",
	"CORD":"VOLUME",
	"CORD_FOOT":"VOLUME",
	"CUBIC_CENTIMETER":"VOLUME",
	"CUBIC_CUBIT":"VOLUME",
	"CUBIC_DECIMETER":"VOLUME",
	"CUBIC_DEKAMETER":"VOLUME",
	"CUBIC_FOOT":"VOLUME",
	"CUBIC_INCH":"VOLUME",
	"CUBIC_KILOMETER":"VOLUME",
	"CUBIC_METER":"VOLUME",
	"CUBIC_MILE":"VOLUME",
	"CUBIC_MICROMETER":"VOLUME",
	"CUBIC_MILLIMETER":"VOLUME",
	"CUBIC_YARD":"VOLUME",
	"CUP_CANADA":"VOLUME",
	"CUP":"VOLUME",
	"CUP_US":"VOLUME",
	"DECILITER":"VOLUME",
	"DEKALITER":"VOLUME",
	"DRUM_US":"VOLUME",
	"DRUM":"VOLUME",
	"FIFTH":"VOLUME",
	"GALLON":"VOLUME",
	"GALLON_US_DRY":"VOLUME",
	"GALLON_US":"VOLUME",
	"GILL":"VOLUME",
	"GILL_US":"VOLUME",
	"HECTARE_METER":"VOLUME",
	"HECTOLITER":"VOLUME",
	"HOGSHEAD":"VOLUME",
	"HOGSHEAD_US":"VOLUME",
	"JIGGER":"VOLUME",
	"KILOLITER":"VOLUME",
	"LITER":"VOLUME",
	"MEASURE":"VOLUME",
	"MEGALITER":"VOLUME",
	"MICROLITER":"VOLUME",
	"MILLILITER":"VOLUME",
	"MINIM":"VOLUME",
	"MINIM_US":"VOLUME",
	"OUNCE_US":"VOLUME",
	"PECK":"VOLUME",
	"PECK_US":"VOLUME",
	"PINT":"VOLUME",
	"PINT_US_DRY":"VOLUME",
	"PINT_US":"VOLUME",
	"PIPE":"VOLUME",
	"PIPE_US":"VOLUME",
	"PONY":"VOLUME",
	"QUART_GERMANY":"VOLUME",
	"QUART_ANCIENT":"VOLUME",
	"QUART":"VOLUME",
	"QUART_US_DRY":"VOLUME",
	"QUART_US":"VOLUME",
	"QUART_UK":"VOLUME",
	"SHOT":"VOLUME",
	"STERE":"VOLUME",
	"TABLESPOON":"VOLUME",
	"TABLESPOON_UK":"VOLUME",
	"TABLESPOON_US":"VOLUME",
	"TEASPOON":"VOLUME",
	"TEASPOON_UK":"VOLUME",
	"TEASPOON_US":"VOLUME",
	"ARRATEL":"WEIGHT",
	"ARTEL":"WEIGHT",
	"ARROBA_PORTUGUESE":"WEIGHT",
	"ARROBA":"WEIGHT",
	"AS_":"WEIGHT",
	"ASS":"WEIGHT",
	"ATOMIC_MASS_UNIT_1960":"WEIGHT",
	"ATOMIC_MASS_UNIT_1973":"WEIGHT",
	"ATOMIC_MASS_UNIT_1986":"WEIGHT",
	"ATOMIC_MASS_UNIT":"WEIGHT",
	"AVOGRAM":"WEIGHT",
	"BAG":"WEIGHT",
	"BAHT":"WEIGHT",
	"BALE":"WEIGHT",
	"BALE_US":"WEIGHT",
	"BISMAR_POUND":"WEIGHT",
	"CANDY":"WEIGHT",
	"CARAT_INTERNATIONAL":"WEIGHT",
	"CARAT":"WEIGHT",
	"CARAT_UK":"WEIGHT",
	"CARAT_US_1913":"WEIGHT",
	"CARGA":"WEIGHT",
	"CATTI":"WEIGHT",
	"CATTI_JAPANESE":"WEIGHT",
	"CATTY":"WEIGHT",
	"CATTY_JAPANESE":"WEIGHT",
	"CATTY_THAI":"WEIGHT",
	"CENTAL":"WEIGHT",
	"CENTIGRAM":"WEIGHT",
	"CENTNER":"WEIGHT",
	"CENTNER_RUSSIAN":"WEIGHT",
	"CHALDER":"WEIGHT",
	"CHALDRON":"WEIGHT",
	"CHIN":"WEIGHT",
	"CHIN_JAPANESE":"WEIGHT",
	"CLOVE":"WEIGHT",
	"CRITH":"WEIGHT",
	"DALTON":"WEIGHT",
	"DAN":"WEIGHT",
	"DAN_JAPANESE":"WEIGHT",
	"DECIGRAM":"WEIGHT",
	"DECITONNE":"WEIGHT",
	"DEKAGRAM":"WEIGHT",
	"DEKATONNE":"WEIGHT",
	"DENARO":"WEIGHT",
	"DENIER":"WEIGHT",
	"DRACHME":"WEIGHT",
	"DRAM_APOTHECARIES":"WEIGHT",
	"DYNE":"WEIGHT",
	"ELECTRON":"WEIGHT",
	"ELECTRONVOLT":"WEIGHT",
	"ETTO":"WEIGHT",
	"EXAGRAM":"WEIGHT",
	"FEMTOGRAM":"WEIGHT",
	"FIRKIN":"WEIGHT",
	"FLASK":"WEIGHT",
	"FOTHER":"WEIGHT",
	"FOTMAL":"WEIGHT",
	"FUNT":"WEIGHT",
	"FUNTE":"WEIGHT",
	"GAMMA":"WEIGHT",
	"GIGAELECTRONVOLT":"WEIGHT",
	"GIGAGRAM":"WEIGHT",
	"GIGATONNE":"WEIGHT",
	"GIN":"WEIGHT",
	"GIN_JAPANESE":"WEIGHT",
	"GRAIN":"WEIGHT",
	"GRAM":"WEIGHT",
	"GRAN":"WEIGHT",
	"GRANO":"WEIGHT",
	"GRANI":"WEIGHT",
	"GROS":"WEIGHT",
	"HECTOGRAM":"WEIGHT",
	"HUNDRETWEIGHT":"WEIGHT",
	"HUNDRETWEIGHT_US":"WEIGHT",
	"HYL":"WEIGHT",
	"JIN":"WEIGHT",
	"JUPITER":"WEIGHT",
	"KATI":"WEIGHT",
	"KATI_JAPANESE":"WEIGHT",
	"KEEL":"WEIGHT",
	"KEG":"WEIGHT",
	"KILODALTON":"WEIGHT",
	"KILOGRAM":"WEIGHT",
	"KILOGRAM_FORCE":"WEIGHT",
	"KILOTON":"WEIGHT",
	"KILOTON_US":"WEIGHT",
	"KILOTONNE":"WEIGHT",
	"KIN":"WEIGHT",
	"KIP":"WEIGHT",
	"KOYAN":"WEIGHT",
	"KWAN":"WEIGHT",
	"LAST_GERMANY":"WEIGHT",
	"LAST":"WEIGHT",
	"LAST_WOOL":"WEIGHT",
	"LB":"WEIGHT",
	"LBS":"WEIGHT",
	"LIANG":"WEIGHT",
	"LIBRE_ITALIAN":"WEIGHT",
	"LIBRA_SPANISH":"WEIGHT",
	"LIBRA_PORTUGUESE":"WEIGHT",
	"LIBRA_ANCIENT":"WEIGHT",
	"LIBRA":"WEIGHT",
	"LIVRE":"WEIGHT",
	"LONG_TON":"WEIGHT",
	"LOT":"WEIGHT",
	"MACE":"WEIGHT",
	"MAHND":"WEIGHT",
	"MARC":"WEIGHT",
	"MARCO":"WEIGHT",
	"MARK":"WEIGHT",
	"MARK_GERMANY":"WEIGHT",
	"MAUND":"WEIGHT",
	"MAUND_PAKISTAN":"WEIGHT",
	"MEGADALTON":"WEIGHT",
	"MEGAGRAM":"WEIGHT",
	"MEGATONNE":"WEIGHT",
	"MERCANTILE_POUND":"WEIGHT",
	"METRIC_TON":"WEIGHT",
	"MIC":"WEIGHT",
	"MICROGRAM":"WEIGHT",
	"MILLIDALTON":"WEIGHT",
	"MILLIER":"WEIGHT",
	"MILLIGRAM":"WEIGHT",
	"MILLIMASS_UNIT":"WEIGHT",
	"MINA":"WEIGHT",
	"MOMME":"WEIGHT",
	"MYRIAGRAM":"WEIGHT",
	"NANOGRAM":"WEIGHT",
	"NEWTON":"WEIGHT",
	"OBOL":"WEIGHT",
	"OBOLOS":"WEIGHT",
	"OBOLUS":"WEIGHT",
	"OBOLOS_ANCIENT":"WEIGHT",
	"OBOLUS_ANCIENT":"WEIGHT",
	"OKA":"WEIGHT",
	"ONCA":"WEIGHT",
	"ONCE":"WEIGHT",
	"ONCIA":"WEIGHT",
	"ONZA":"WEIGHT",
	"ONS":"WEIGHT",
	"OUNCE_FORCE":"WEIGHT",
	"OUNCE_TROY":"WEIGHT",
	"PACKEN":"WEIGHT",
	"PENNYWEIGHT":"WEIGHT",
	"PETAGRAM":"WEIGHT",
	"PFUND":"WEIGHT",
	"PICOGRAM":"WEIGHT",
	"POND":"WEIGHT",
	"POUND":"WEIGHT",
	"POUND_FORCE":"WEIGHT",
	"POUND_METRIC":"WEIGHT",
	"POUND_TROY":"WEIGHT",
	"PUD":"WEIGHT",
	"POOD":"WEIGHT",
	"PUND":"WEIGHT",
	"QIAN":"WEIGHT",
	"QINTAR":"WEIGHT",
	"QUARTER_US":"WEIGHT",
	"QUARTER_TON":"WEIGHT",
	"QUARTERN":"WEIGHT",
	"QUARTERN_LOAF":"WEIGHT",
	"QUINTAL_FRENCH":"WEIGHT",
	"QUINTAL":"WEIGHT",
	"QUINTAL_PORTUGUESE":"WEIGHT",
	"QUINTAL_SPAIN":"WEIGHT",
	"REBAH":"WEIGHT",
	"ROTL":"WEIGHT",
	"ROTEL":"WEIGHT",
	"ROTTLE":"WEIGHT",
	"RATEL":"WEIGHT",
	"SACK":"WEIGHT",
	"SCRUPLE":"WEIGHT",
	"SEER":"WEIGHT",
	"SEER_PAKISTAN":"WEIGHT",
	"SHEKEL":"WEIGHT",
	"SHORT_TON":"WEIGHT",
	"SLINCH":"WEIGHT",
	"SLUG":"WEIGHT",
	"STONE":"WEIGHT",
	"TAEL":"WEIGHT",
	"TAHIL_JAPANESE":"WEIGHT",
	"TAHIL":"WEIGHT",
	"TALENT":"WEIGHT",
	"TAN":"WEIGHT",
	"TECHNISCHE_MASS_EINHEIT":"WEIGHT",
	"TERAGRAM":"WEIGHT",
	"TETRADRACHM":"WEIGHT",
	"TICAL":"WEIGHT",
	"TOD":"WEIGHT",
	"TOLA":"WEIGHT",
	"TOLA_PAKISTAN":"WEIGHT",
	"TON_UK":"WEIGHT",
	"TON":"WEIGHT",
	"TON_US":"WEIGHT",
	"TONELADA_PORTUGUESE":"WEIGHT",
	"TONELADA":"WEIGHT",
	"TONNE":"WEIGHT",
	"TONNEAU":"WEIGHT",
	"TOVAR":"WEIGHT",
	"TROY_OUNCE":"WEIGHT",
	"TROY_POUND":"WEIGHT",
	"TRUSS":"WEIGHT",
	"UNCIA":"WEIGHT",
	"UNZE":"WEIGHT",
	"VAGON":"WEIGHT",
	"YOCTOGRAM":"WEIGHT",
	"YOTTAGRAM":"WEIGHT",
	"ZENTNER":"WEIGHT",
	"ZEPTOGRAM":"WEIGHT",
	"ZETTAGRAM":"WEIGHT"
};

    _public.unitDefinition = {
	"ACCELERATION":{
		"STANDARD":"METER_PER_SQUARE_SECOND",
		"UNITS":{
			"CENTIGAL":{
				"factor":0.0001,
				"label":"cgal"
			},
			"CENTIMETER_PER_SQUARE_SECOND":{
				"factor":0.01,
				"label":"cm\/s\u00b2"
			},
			"DECIGAL":{
				"factor":0.001,
				"label":"dgal"
			},
			"DECIMETER_PER_SQUARE_SECOND":{
				"factor":0.1,
				"label":"dm\/s\u00b2"
			},
			"DEKAMETER_PER_SQUARE_SECOND":{
				"factor":10,
				"label":"dam\/s\u00b2"
			},
			"FOOT_PER_SQUARE_SECOND":{
				"factor":0.3048,
				"label":"ft\/s\u00b2"
			},
			"G":{
				"factor":9.80665,
				"label":"g"
			},
			"GAL":{
				"factor":0.01,
				"label":"gal"
			},
			"GALILEO":{
				"factor":0.01,
				"label":"gal"
			},
			"GRAV":{
				"factor":9.80665,
				"label":"g"
			},
			"HECTOMETER_PER_SQUARE_SECOND":{
				"factor":100,
				"label":"h\/s\u00b2"
			},
			"INCH_PER_SQUARE_SECOND":{
				"factor":0.0254,
				"label":"in\/s\u00b2"
			},
			"KILOMETER_PER_HOUR_SECOND":{
				"factor":[
					{
						"op":"",
						"val":5
					},
					{
						"op":"\/",
						"val":18
					}
				],
				"label":"km\/h\u00b2"
			},
			"KILOMETER_PER_SQUARE_SECOND":{
				"factor":1000,
				"label":"km\/s\u00b2"
			},
			"METER_PER_SQUARE_SECOND":{
				"factor":1,
				"label":"m\/s\u00b2"
			},
			"MILE_PER_HOUR_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":22
					},
					{
						"op":"\/",
						"val":60
					},
					{
						"op":"*",
						"val":0.3048
					}
				],
				"label":"mph\/m"
			},
			"MILE_PER_HOUR_SECOND":{
				"factor":[
					{
						"op":"",
						"val":22
					},
					{
						"op":"\/",
						"val":15
					},
					{
						"op":"*",
						"val":0.3048
					}
				],
				"label":"mph\/s"
			},
			"MILE_PER_SQUARE_SECOND":{
				"factor":1609.344,
				"label":"mi\/s\u00b2"
			},
			"MILLIGAL":{
				"factor":1.0e-5,
				"label":"mgal"
			},
			"MILLIMETER_PER_SQUARE_SECOND":{
				"factor":0.001,
				"label":"mm\/s\u00b2"
			}
		}
	},
	"ANGLE":[

	],
	"AREA":[

	],
	"BINARY":{
		"STANDARD":"BYTE",
		"UNITS":{
			"BIT":{
				"factor":0.125,
				"label":"b"
			},
			"CRUMB":{
				"factor":0.25,
				"label":"crumb"
			},
			"NIBBLE":{
				"factor":0.5,
				"label":"nibble"
			},
			"BYTE":{
				"factor":1,
				"label":"B"
			},
			"KILOBYTE":{
				"factor":1024,
				"label":"kB"
			},
			"KIBIBYTE":{
				"factor":1024,
				"label":"KiB"
			},
			"KILO_BINARY_BYTE":{
				"factor":1024,
				"label":"KiB"
			},
			"KILOBYTE_SI":{
				"factor":1000,
				"label":"kB."
			},
			"MEGABYTE":{
				"factor":1048576,
				"label":"MB"
			},
			"MEBIBYTE":{
				"factor":1048576,
				"label":"MiB"
			},
			"MEGA_BINARY_BYTE":{
				"factor":1048576,
				"label":"MiB"
			},
			"MEGABYTE_SI":{
				"factor":1000000,
				"label":"MB."
			},
			"GIGABYTE":{
				"factor":1073741824,
				"label":"GB"
			},
			"GIBIBYTE":{
				"factor":1073741824,
				"label":"GiB"
			},
			"GIGA_BINARY_BYTE":{
				"factor":1073741824,
				"label":"GiB"
			},
			"GIGABYTE_SI":{
				"factor":1000000000,
				"label":"GB."
			},
			"TERABYTE":{
				"factor":1099511627776,
				"label":"TB"
			},
			"TEBIBYTE":{
				"factor":1099511627776,
				"label":"TiB"
			},
			"TERA_BINARY_BYTE":{
				"factor":1099511627776,
				"label":"TiB"
			},
			"TERABYTE_SI":{
				"factor":1000000000000,
				"label":"TB."
			},
			"PETABYTE":{
				"factor":1.1258999068426e+15,
				"label":"PB"
			},
			"PEBIBYTE":{
				"factor":1.1258999068426e+15,
				"label":"PiB"
			},
			"PETA_BINARY_BYTE":{
				"factor":1.1258999068426e+15,
				"label":"PiB"
			},
			"PETABYTE_SI":{
				"factor":1.0e+15,
				"label":"PB."
			},
			"EXABYTE":{
				"factor":1.1529215046068e+18,
				"label":"EB"
			},
			"EXBIBYTE":{
				"factor":1.1529215046068e+18,
				"label":"EiB"
			},
			"EXA_BINARY_BYTE":{
				"factor":1.1529215046068e+18,
				"label":"EiB"
			},
			"EXABYTE_SI":{
				"factor":1.0e+18,
				"label":"EB."
			},
			"ZETTABYTE":{
				"factor":1.1805916207174e+21,
				"label":"ZB"
			},
			"ZEBIBYTE":{
				"factor":1.1805916207174e+21,
				"label":"ZiB"
			},
			"ZETTA_BINARY_BYTE":{
				"factor":1.1805916207174e+21,
				"label":"ZiB"
			},
			"ZETTABYTE_SI":{
				"factor":1.0e+21,
				"label":"ZB."
			},
			"YOTTABYTE":{
				"factor":1.2089258196146e+24,
				"label":"YB"
			},
			"YOBIBYTE":{
				"factor":1.2089258196146e+24,
				"label":"YiB"
			},
			"YOTTA_BINARY_BYTE":{
				"factor":1.2089258196146e+24,
				"label":"YiB"
			},
			"YOTTABYTE_SI":{
				"factor":1.0e+24,
				"label":"YB."
			}
		}
	},
	"CAPACITANCE":{
		"STANDARD":"FARAD",
		"UNITS":{
			"ABFARAD":{
				"factor":1000000000,
				"label":"abfarad"
			},
			"AMPERE_PER_SECOND_VOLT":{
				"factor":1,
				"label":"A\/sV"
			},
			"CENTIFARAD":{
				"factor":0.01,
				"label":"cF"
			},
			"COULOMB_PER_VOLT":{
				"factor":1,
				"label":"C\/V"
			},
			"DECIFARAD":{
				"factor":0.1,
				"label":"dF"
			},
			"DEKAFARAD":{
				"factor":10,
				"label":"daF"
			},
			"ELECTROMAGNETIC_UNIT":{
				"factor":1000000000,
				"label":"capacity emu"
			},
			"ELECTROSTATIC_UNIT":{
				"factor":1.11265e-12,
				"label":"capacity esu"
			},
			"FARAD":{
				"factor":1,
				"label":"F"
			},
			"FARAD_INTERNATIONAL":{
				"factor":0.99951,
				"label":"F"
			},
			"GAUSSIAN":{
				"factor":1.11265e-12,
				"label":"G"
			},
			"GIGAFARAD":{
				"factor":1000000000,
				"label":"GF"
			},
			"HECTOFARAD":{
				"factor":100,
				"label":"hF"
			},
			"JAR":{
				"factor":1.11265e-9,
				"label":"jar"
			},
			"KILOFARAD":{
				"factor":1000,
				"label":"kF"
			},
			"MEGAFARAD":{
				"factor":1000000,
				"label":"MF"
			},
			"MICROFARAD":{
				"factor":1.0e-6,
				"label":"\u00b5F"
			},
			"MILLIFARAD":{
				"factor":0.001,
				"label":"mF"
			},
			"NANOFARAD":{
				"factor":1.0e-9,
				"label":"nF"
			},
			"PICOFARAD":{
				"factor":1.0e-12,
				"label":"pF"
			},
			"PUFF":{
				"factor":1.0e-12,
				"label":"pF"
			},
			"SECOND_PER_OHM":{
				"factor":1,
				"label":"s\/Ohm"
			},
			"STATFARAD":{
				"factor":1.11265e-12,
				"label":"statfarad"
			},
			"TERAFARAD":{
				"factor":1000000000000,
				"label":"TF"
			}
		}
	},
	"CURRENT":[

	],
	"DENSITY":{
		"STANDARD":"KILOGRAM_PER_CUBIC_METER",
		"UNITS":{
			"ALUMINIUM":{
				"factor":2643,
				"label":"aluminium"
			},
			"COPPER":{
				"factor":8906,
				"label":"copper"
			},
			"GOLD":{
				"factor":19300,
				"label":"gold"
			},
			"GRAIN_PER_CUBIC_FOOT":{
				"factor":0.0022883519,
				"label":"gr\/ft\u00b3"
			},
			"GRAIN_PER_CUBIC_INCH":{
				"factor":3.9542721,
				"label":"gr\/in\u00b3"
			},
			"GRAIN_PER_CUBIC_YARD":{
				"factor":8.4753774e-5,
				"label":"gr\/yd\u00b3"
			},
			"GRAIN_PER_GALLON":{
				"factor":0.014253768,
				"label":"gr\/gal"
			},
			"GRAIN_PER_GALLON_US":{
				"factor":0.017118061,
				"label":"gr\/gal"
			},
			"GRAM_PER_CUBIC_CENTIMETER":{
				"factor":1000,
				"label":"g\/cm\u00b3"
			},
			"GRAM_PER_CUBIC_DECIMETER":{
				"factor":1,
				"label":"g\/dm\u00b3"
			},
			"GRAM_PER_CUBIC_METER":{
				"factor":0.001,
				"label":"g\/m\u00b3"
			},
			"GRAM_PER_LITER":{
				"factor":1,
				"label":"g\/l"
			},
			"GRAM_PER_MILLILITER":{
				"factor":1000,
				"label":"g\/ml"
			},
			"KILOGRAM_PER_CUBIC_CENTIMETER":{
				"factor":1000000,
				"label":"kg\/cm\u00b3"
			},
			"KILOGRAM_PER_CUBIC_DECIMETER":{
				"factor":1000,
				"label":"kg\/dm\u00b3"
			},
			"KILOGRAM_PER_CUBIC_METER":{
				"factor":1,
				"label":"kg\/m\u00b3"
			},
			"KILOGRAM_PER_CUBIC_MILLIMETER":{
				"factor":1000000000,
				"label":"kg\/l"
			},
			"KILOGRAM_PER_LITER":{
				"factor":1000,
				"label":"kg\/ml"
			},
			"KILOGRAM_PER_MILLILITER":{
				"factor":1000000,
				"label":"kg\/ml"
			},
			"LEAD":{
				"factor":11370,
				"label":"lead"
			},
			"MEGAGRAM_PER_CUBIC_CENTIMETER":{
				"factor":1000000000,
				"label":"Mg\/cm\u00b3"
			},
			"MEGAGRAM_PER_CUBIC_DECIMETER":{
				"factor":1000000,
				"label":"Mg\/dm\u00b3"
			},
			"MEGAGRAM_PER_CUBIC_METER":{
				"factor":1000,
				"label":"Mg\/m\u00b3"
			},
			"MEGAGRAM_PER_LITER":{
				"factor":1000000,
				"label":"Mg\/l"
			},
			"MEGAGRAM_PER_MILLILITER":{
				"factor":1000000000,
				"label":"Mg\/ml"
			},
			"MICROGRAM_PER_CUBIC_CENTIMETER":{
				"factor":0.001,
				"label":"\u00b5g\/cm\u00b3"
			},
			"MICROGRAM_PER_CUBIC_DECIMETER":{
				"factor":1.0e-6,
				"label":"\u00b5g\/dm\u00b3"
			},
			"MICROGRAM_PER_CUBIC_METER":{
				"factor":1.0e-9,
				"label":"\u00b5g\/m\u00b3"
			},
			"MICROGRAM_PER_LITER":{
				"factor":1.0e-6,
				"label":"\u00b5g\/l"
			},
			"MICROGRAM_PER_MILLILITER":{
				"factor":0.001,
				"label":"\u00b5g\/ml"
			},
			"MILLIGRAM_PER_CUBIC_CENTIMETER":{
				"factor":1,
				"label":"mg\/cm\u00b3"
			},
			"MILLIGRAM_PER_CUBIC_DECIMETER":{
				"factor":0.001,
				"label":"mg\/dm\u00b3"
			},
			"MILLIGRAM_PER_CUBIC_METER":{
				"factor":1.0e-6,
				"label":"mg\/m\u00b3"
			},
			"MILLIGRAM_PER_LITER":{
				"factor":0.001,
				"label":"mg\/l"
			},
			"MILLIGRAM_PER_MILLILITER":{
				"factor":1,
				"label":"mg\/ml"
			},
			"OUNCE_PER_CUBIC_FOOT":{
				"factor":1.001154,
				"label":"oz\/ft\u00b3"
			},
			"OUNCE_PER_CUBIC_FOOT_TROY":{
				"factor":1.0984089,
				"label":"oz\/ft\u00b3"
			},
			"OUNCE_PER_CUBIC_INCH":{
				"factor":1729.994,
				"label":"oz\/in\u00b3"
			},
			"OUNCE_PER_CUBIC_INCH_TROY":{
				"factor":1898.0506,
				"label":"oz\/in\u00b3"
			},
			"OUNCE_PER_CUBIC_YARD":{
				"factor":0.037079776,
				"label":"oz\/yd\u00b3"
			},
			"OUNCE_PER_CUBIC_YARD_TROY":{
				"factor":0.040681812,
				"label":"oz\/yd\u00b3"
			},
			"OUNCE_PER_GALLON":{
				"factor":6.2360233,
				"label":"oz\/gal"
			},
			"OUNCE_PER_GALLON_US":{
				"factor":7.4891517,
				"label":"oz\/gal"
			},
			"OUNCE_PER_GALLON_TROY":{
				"factor":6.8418084,
				"label":"oz\/gal"
			},
			"OUNCE_PER_GALLON_US_TROY":{
				"factor":8.2166693,
				"label":"oz\/gal"
			},
			"POUND_PER_CIRCULAR_MIL_FOOT":{
				"factor":2.9369291,
				"label":"lb\/cmil ft"
			},
			"POUND_PER_CUBIC_FOOT":{
				"factor":16.018463,
				"label":"lb\/in\u00b3"
			},
			"POUND_PER_CUBIC_INCH":{
				"factor":27679.905,
				"label":"lb\/in\u00b3"
			},
			"POUND_PER_CUBIC_YARD":{
				"factor":0.59327642,
				"label":"lb\/yd\u00b3"
			},
			"POUND_PER_GALLON":{
				"factor":99.776373,
				"label":"lb\/gal"
			},
			"POUND_PER_KILOGALLON":{
				"factor":0.099776373,
				"label":"lb\/kgal"
			},
			"POUND_PER_MEGAGALLON":{
				"factor":9.9776373e-5,
				"label":"lb\/Mgal"
			},
			"POUND_PER_GALLON_US":{
				"factor":119.82643,
				"label":"lb\/gal"
			},
			"POUND_PER_KILOGALLON_US":{
				"factor":0.11982643,
				"label":"lb\/kgal"
			},
			"POUND_PER_MEGAGALLON_US":{
				"factor":0.00011982643,
				"label":"lb\/Mgal"
			},
			"SILVER":{
				"factor":10510,
				"label":"silver"
			},
			"SLUG_PER_CUBIC_FOOT":{
				"factor":515.37882,
				"label":"slug\/ft\u00b3"
			},
			"SLUG_PER_CUBIC_INCH":{
				"factor":890574.6,
				"label":"slug\/in\u00b3"
			},
			"SLUG_PER_CUBIC_YARD":{
				"factor":19.088104,
				"label":"slug\/yd\u00b3"
			},
			"SLUG_PER_GALLON":{
				"factor":3210.2099,
				"label":"slug\/gal"
			},
			"SLUG_PER_GALLON_US":{
				"factor":3855.3013,
				"label":"slug\/gal"
			},
			"TON_PER_CUBIC_FOOT_LONG":{
				"factor":35881.358,
				"label":"t\/ft\u00b3"
			},
			"TON_PER_CUBIC_FOOT":{
				"factor":32036.927,
				"label":"t\/ft\u00b3"
			},
			"TON_PER_CUBIC_INCH_LONG":{
				"factor":62202987,
				"label":"t\/in\u00b3"
			},
			"TON_PER_CUBIC_INCH":{
				"factor":55359809,
				"label":"t\/in\u00b3"
			},
			"TON_PER_CUBIC_YARD_LONG":{
				"factor":1328.9392,
				"label":"t\/yd\u00b3"
			},
			"TON_PER_CUBIC_YARD":{
				"factor":1186.5528,
				"label":"t\/yd\u00b3"
			},
			"TON_PER_GALLON_LONG":{
				"factor":223499.07,
				"label":"t\/gal"
			},
			"TON_PER_GALLON_US_LONG":{
				"factor":268411.2,
				"label":"t\/gal"
			},
			"TON_PER_GALLON":{
				"factor":199522.75,
				"label":"t\/gal"
			},
			"TON_PER_GALLON_US":{
				"factor":239652.85,
				"label":"t\/gal"
			},
			"TONNE_PER_CUBIC_CENTIMETER":{
				"factor":1000000000,
				"label":"t\/cm\u00b3"
			},
			"TONNE_PER_CUBIC_DECIMETER":{
				"factor":1000000,
				"label":"t\/dm\u00b3"
			},
			"TONNE_PER_CUBIC_METER":{
				"factor":1000,
				"label":"t\/m\u00b3"
			},
			"TONNE_PER_LITER":{
				"factor":1000000,
				"label":"t\/l"
			},
			"TONNE_PER_MILLILITER":{
				"factor":1000000000,
				"label":"t\/ml"
			},
			"WATER":{
				"factor":1000,
				"label":"water"
			}
		}
	},
	"ENERGY":[

	],
	"EXCEPTION":[

	],
	"FORCE":[

	],
	"FREQUENCY":{
		"STANDARD":"HERTZ",
		"UNITS":{
			"ONE_PER_SECOND":{
				"factor":1,
				"label":"1\/s"
			},
			"CYCLE_PER_SECOND":{
				"factor":1,
				"label":"cps"
			},
			"DEGREE_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":1296000
					}
				],
				"label":"\u00b0\/h"
			},
			"DEGREE_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":21600
					}
				],
				"label":"\u00b0\/m"
			},
			"DEGREE_PER_SECOND":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":360
					}
				],
				"label":"\u00b0\/s"
			},
			"GIGAHERTZ":{
				"factor":1000000000,
				"label":"GHz"
			},
			"HERTZ":{
				"factor":1,
				"label":"Hz"
			},
			"KILOHERTZ":{
				"factor":1000,
				"label":"kHz"
			},
			"MEGAHERTZ":{
				"factor":1000000,
				"label":"MHz"
			},
			"MILLIHERTZ":{
				"factor":0.001,
				"label":"mHz"
			},
			"RADIAN_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":22619.467
					}
				],
				"label":"rad\/h"
			},
			"RADIAN_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":376.99112
					}
				],
				"label":"rad\/m"
			},
			"RADIAN_PER_SECOND":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":6.2831853
					}
				],
				"label":"rad\/s"
			},
			"REVOLUTION_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"rph"
			},
			"REVOLUTION_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"rpm"
			},
			"REVOLUTION_PER_SECOND":{
				"factor":1,
				"label":"rps"
			},
			"RPM":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"rpm"
			},
			"TERRAHERTZ":{
				"factor":1000000000000,
				"label":"THz"
			}
		}
	},
	"ILLUMINATION":{
		"STANDARD":"LUX",
		"UNITS":{
			"FOOTCANDLE":{
				"factor":10.7639104,
				"label":"fc"
			},
			"KILOLUX":{
				"factor":1000,
				"label":"klx"
			},
			"LUMEN_PER_SQUARE_CENTIMETER":{
				"factor":10000,
				"label":"lm\/cm\u00b2"
			},
			"LUMEN_PER_SQUARE_FOOT":{
				"factor":10.7639104,
				"label":"lm\/ft\u00b2"
			},
			"LUMEN_PER_SQUARE_INCH":{
				"factor":1550.0030976,
				"label":"lm\/in\u00b2"
			},
			"LUMEN_PER_SQUARE_METER":{
				"factor":1,
				"label":"lm\/m\u00b2"
			},
			"LUX":{
				"factor":1,
				"label":"lx"
			},
			"METERCANDLE":{
				"factor":1,
				"label":"metercandle"
			},
			"MILLIPHOT":{
				"factor":10,
				"label":"mph"
			},
			"NOX":{
				"factor":0.001,
				"label":"nox"
			},
			"PHOT":{
				"factor":10000,
				"label":"ph"
			}
		}
	},
	"LENGTH":{
		"STANDARD":"METER",
		"UNITS":{
			"AGATE":{
				"factor":[
					{
						"op":"",
						"val":0.0254
					},
					{
						"op":"\/",
						"val":72
					}
				],
				"label":"agate"
			},
			"ALEN_DANISH":{
				"factor":0.6277,
				"label":"alen"
			},
			"ALEN":{
				"factor":0.6,
				"label":"alen"
			},
			"ALEN_SWEDISH":{
				"factor":0.5938,
				"label":"alen"
			},
			"ANGSTROM":{
				"factor":1.0e-10,
				"label":"\u00c5"
			},
			"ARMS":{
				"factor":0.7,
				"label":"arms"
			},
			"ARPENT_CANADIAN":{
				"factor":58.47,
				"label":"arpent"
			},
			"ARPENT":{
				"factor":58.471308,
				"label":"arpent"
			},
			"ARSHEEN":{
				"factor":0.7112,
				"label":"arsheen"
			},
			"ARSHIN":{
				"factor":1.04,
				"label":"arshin"
			},
			"ARSHIN_IRAQ":{
				"factor":74.5,
				"label":"arshin"
			},
			"ASTRONOMICAL_UNIT":{
				"factor":149597870691,
				"label":"AU"
			},
			"ATTOMETER":{
				"factor":1.0e-18,
				"label":"am"
			},
			"BAMBOO":{
				"factor":3.2,
				"label":"bamboo"
			},
			"BARLEYCORN":{
				"factor":0.0085,
				"label":"barleycorn"
			},
			"BEE_SPACE":{
				"factor":0.0065,
				"label":"bee space"
			},
			"BICRON":{
				"factor":1.0e-12,
				"label":"\u00b5\u00b5"
			},
			"BLOCK_US_EAST":{
				"factor":80.4672,
				"label":"block"
			},
			"BLOCK_US_WEST":{
				"factor":100.584,
				"label":"block"
			},
			"BLOCK_US_SOUTH":{
				"factor":160.9344,
				"label":"block"
			},
			"BOHR":{
				"factor":5.2918e-11,
				"label":"a\u2080"
			},
			"BRACCIO":{
				"factor":0.7,
				"label":"braccio"
			},
			"BRAZA_ARGENTINA":{
				"factor":1.733,
				"label":"braza"
			},
			"BRAZA":{
				"factor":1.67,
				"label":"braza"
			},
			"BRAZA_US":{
				"factor":1.693,
				"label":"braza"
			},
			"BUTTON":{
				"factor":0.000635,
				"label":"button"
			},
			"CABLE_US":{
				"factor":219.456,
				"label":"cable"
			},
			"CABLE_UK":{
				"factor":185.3184,
				"label":"cable"
			},
			"CALIBER":{
				"factor":0.0254,
				"label":"cal"
			},
			"CANA":{
				"factor":2,
				"label":"cana"
			},
			"CAPE_FOOT":{
				"factor":0.314858,
				"label":"cf"
			},
			"CAPE_INCH":{
				"factor":[
					{
						"op":"",
						"val":0.314858
					},
					{
						"op":"\/",
						"val":12
					}
				],
				"label":"ci"
			},
			"CAPE_ROOD":{
				"factor":3.778296,
				"label":"cr"
			},
			"CENTIMETER":{
				"factor":0.01,
				"label":"cm"
			},
			"CHAIN":{
				"factor":[
					{
						"op":"",
						"val":79200
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"ch"
			},
			"CHAIN_ENGINEER":{
				"factor":30.48,
				"label":"ch"
			},
			"CHIH":{
				"factor":0.35814,
				"label":"ch'ih"
			},
			"CHINESE_FOOT":{
				"factor":0.371475,
				"label":"ft"
			},
			"CHINESE_INCH":{
				"factor":0.0371475,
				"label":"in"
			},
			"CHINESE_MILE":{
				"factor":557.21,
				"label":"mi"
			},
			"CHINESE_YARD":{
				"factor":0.89154,
				"label":"yd"
			},
			"CITY_BLOCK_US_EAST":{
				"factor":80.4672,
				"label":"block"
			},
			"CITY_BLOCK_US_WEST":{
				"factor":100.584,
				"label":"block"
			},
			"CITY_BLOCK_US_SOUTH":{
				"factor":160.9344,
				"label":"block"
			},
			"CLICK":{
				"factor":1000,
				"label":"click"
			},
			"CUADRA":{
				"factor":84,
				"label":"cuadra"
			},
			"CUADRA_ARGENTINA":{
				"factor":130,
				"label":"cuadra"
			},
			"Length:CUBIT_EGYPT":{
				"factor":0.45,
				"label":"cubit"
			},
			"CUBIT_ROYAL":{
				"factor":0.5235,
				"label":"cubit"
			},
			"CUBIT_UK":{
				"factor":0.4572,
				"label":"cubit"
			},
			"CUBIT":{
				"factor":0.444,
				"label":"cubit"
			},
			"CUERDA":{
				"factor":21,
				"label":"cda"
			},
			"DECIMETER":{
				"factor":0.1,
				"label":"dm"
			},
			"DEKAMETER":{
				"factor":10,
				"label":"dam"
			},
			"DIDOT_POINT":{
				"factor":0.000377,
				"label":"didot point"
			},
			"DIGIT":{
				"factor":0.019,
				"label":"digit"
			},
			"DIRAA":{
				"factor":0.58,
				"label":""
			},
			"DONG":{
				"factor":[
					{
						"op":"",
						"val":7
					},
					{
						"op":"\/",
						"val":300
					}
				],
				"label":"dong"
			},
			"DOUZIEME_WATCH":{
				"factor":0.000188,
				"label":"douzi\u00e8me"
			},
			"DOUZIEME":{
				"factor":0.00017638888889,
				"label":"douzi\u00e8me"
			},
			"DRA_IRAQ":{
				"factor":0.745,
				"label":"dra"
			},
			"DRA":{
				"factor":0.7112,
				"label":"dra"
			},
			"EL":{
				"factor":0.69,
				"label":"el"
			},
			"ELL":{
				"factor":1.143,
				"label":"ell"
			},
			"ELL_SCOTTISH":{
				"factor":0.945,
				"label":"ell"
			},
			"ELLE":{
				"factor":0.6,
				"label":"ellen"
			},
			"ELLE_VIENNA":{
				"factor":0.7793,
				"label":"ellen"
			},
			"EM":{
				"factor":0.0042175176,
				"label":"em"
			},
			"ESTADIO_PORTUGAL":{
				"factor":261,
				"label":"estadio"
			},
			"ESTADIO":{
				"factor":174,
				"label":"estadio"
			},
			"EXAMETER":{
				"factor":1.0e+18,
				"label":"Em"
			},
			"FADEN_AUSTRIA":{
				"factor":1.8965,
				"label":"faden"
			},
			"FADEN":{
				"factor":1.8,
				"label":"faden"
			},
			"FALL":{
				"factor":6.858,
				"label":"fall"
			},
			"FALL_SCOTTISH":{
				"factor":5.67,
				"label":"fall"
			},
			"FATHOM":{
				"factor":1.8288,
				"label":"fth"
			},
			"FATHOM_ANCIENT":{
				"factor":1.829,
				"label":"fth"
			},
			"FAUST":{
				"factor":0.10536,
				"label":"faust"
			},
			"FEET_OLD_CANADIAN":{
				"factor":0.325,
				"label":"ft"
			},
			"FEET_EGYPT":{
				"factor":0.36,
				"label":"ft"
			},
			"FEET_FRANCE":{
				"factor":0.3248406,
				"label":"ft"
			},
			"FEET":{
				"factor":0.3048,
				"label":"ft"
			},
			"FEET_IRAQ":{
				"factor":0.316,
				"label":"ft"
			},
			"FEET_NETHERLAND":{
				"factor":0.28313,
				"label":"ft"
			},
			"FEET_ITALIC":{
				"factor":0.296,
				"label":"ft"
			},
			"FEET_SURVEY":{
				"factor":[
					{
						"op":"",
						"val":1200
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"ft"
			},
			"FEMTOMETER":{
				"factor":1.0e-15,
				"label":"fm"
			},
			"FERMI":{
				"factor":1.0e-15,
				"label":"f"
			},
			"FINGER":{
				"factor":0.1143,
				"label":"finger"
			},
			"FINGERBREADTH":{
				"factor":0.01905,
				"label":"fingerbreadth"
			},
			"FIST":{
				"factor":0.1,
				"label":"fist"
			},
			"FOD":{
				"factor":0.3141,
				"label":"fod"
			},
			"FOOT_EGYPT":{
				"factor":0.36,
				"label":"ft"
			},
			"FOOT_FRANCE":{
				"factor":0.3248406,
				"label":"ft"
			},
			"FOOT":{
				"factor":0.3048,
				"label":"ft"
			},
			"FOOT_IRAQ":{
				"factor":0.316,
				"label":"ft"
			},
			"FOOT_NETHERLAND":{
				"factor":0.28313,
				"label":"ft"
			},
			"FOOT_ITALIC":{
				"factor":0.296,
				"label":"ft"
			},
			"FOOT_SURVEY":{
				"factor":[
					{
						"op":"",
						"val":1200
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"ft"
			},
			"FOOTBALL_FIELD_CANADA":{
				"factor":100.584,
				"label":"football field"
			},
			"FOOTBALL_FIELD_US":{
				"factor":91.44,
				"label":"football field"
			},
			"FOOTBALL_FIELD":{
				"factor":109.728,
				"label":"football field"
			},
			"FURLONG":{
				"factor":201.168,
				"label":"fur"
			},
			"FURLONG_SURVEY":{
				"factor":[
					{
						"op":"",
						"val":792000
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"fur"
			},
			"FUSS":{
				"factor":0.31608,
				"label":"fuss"
			},
			"GIGAMETER":{
				"factor":1000000000,
				"label":"Gm"
			},
			"GIGAPARSEC":{
				"factor":3.085678e+25,
				"label":"Gpc"
			},
			"GNATS_EYE":{
				"factor":0.000125,
				"label":"gnat's eye"
			},
			"GOAD":{
				"factor":1.3716,
				"label":"goad"
			},
			"GRY":{
				"factor":0.000211667,
				"label":"gry"
			},
			"HAIRS_BREADTH":{
				"factor":0.0001,
				"label":"hair's breadth"
			},
			"HAND":{
				"factor":0.1016,
				"label":"hand"
			},
			"HANDBREADTH":{
				"factor":0.08,
				"label":"hand's breadth"
			},
			"HAT":{
				"factor":0.5,
				"label":"hat"
			},
			"HECTOMETER":{
				"factor":100,
				"label":"hm"
			},
			"HEER":{
				"factor":73.152,
				"label":"heer"
			},
			"HIRO":{
				"factor":1.818,
				"label":"hiro"
			},
			"HUBBLE":{
				"factor":9.4605e+24,
				"label":"hubble"
			},
			"HVAT":{
				"factor":1.8965,
				"label":"hvat"
			},
			"INCH":{
				"factor":0.0254,
				"label":"in"
			},
			"KEN":{
				"factor":1.818,
				"label":"ken"
			},
			"KERAT":{
				"factor":0.0286,
				"label":"kerat"
			},
			"KILOFOOT":{
				"factor":304.8,
				"label":"kft"
			},
			"KILOMETER":{
				"factor":1000,
				"label":"km"
			},
			"KILOPARSEC":{
				"factor":3.0856776e+19,
				"label":"kpc"
			},
			"KILOYARD":{
				"factor":914.4,
				"label":"kyd"
			},
			"KIND":{
				"factor":0.5,
				"label":"kind"
			},
			"KLAFTER":{
				"factor":1.8965,
				"label":"klafter"
			},
			"KLAFTER_SWISS":{
				"factor":1.8,
				"label":"klafter"
			},
			"KLICK":{
				"factor":1000,
				"label":"klick"
			},
			"KYU":{
				"factor":0.00025,
				"label":"kyu"
			},
			"LAP_ANCIENT":{
				"factor":402.336,
				"label":""
			},
			"LAP":{
				"factor":400,
				"label":"lap"
			},
			"LAP_POOL":{
				"factor":100,
				"label":"lap"
			},
			"LEAGUE_ANCIENT":{
				"factor":2275,
				"label":"league"
			},
			"LEAGUE_NAUTIC":{
				"factor":5556,
				"label":"league"
			},
			"LEAGUE_UK_NAUTIC":{
				"factor":5559.552,
				"label":"league"
			},
			"LEAGUE":{
				"factor":4828,
				"label":"league"
			},
			"LEAGUE_US":{
				"factor":4828.0417,
				"label":"league"
			},
			"LEAP":{
				"factor":2.0574,
				"label":"leap"
			},
			"LEGOA":{
				"factor":6174.1,
				"label":"legoa"
			},
			"LEGUA":{
				"factor":4200,
				"label":"legua"
			},
			"LEGUA_US":{
				"factor":4233.4,
				"label":"legua"
			},
			"LEGUA_SPAIN_OLD":{
				"factor":4179.4,
				"label":"legua"
			},
			"LEGUA_SPAIN":{
				"factor":6680,
				"label":"legua"
			},
			"LI_ANCIENT":{
				"factor":500,
				"label":"li"
			},
			"LI_IMPERIAL":{
				"factor":644.65,
				"label":"li"
			},
			"LI":{
				"factor":500,
				"label":"li"
			},
			"LIEUE":{
				"factor":3898,
				"label":"lieue"
			},
			"LIEUE_METRIC":{
				"factor":4000,
				"label":"lieue"
			},
			"LIEUE_NAUTIC":{
				"factor":5556,
				"label":"lieue"
			},
			"LIGHT_SECOND":{
				"factor":299792458,
				"label":"light second"
			},
			"LIGHT_MINUTE":{
				"factor":17987547480,
				"label":"light minute"
			},
			"LIGHT_HOUR":{
				"factor":1079252848800,
				"label":"light hour"
			},
			"LIGHT_DAY":{
				"factor":25902068371200,
				"label":"light day"
			},
			"LIGHT_YEAR":{
				"factor":9.460528404879e+15,
				"label":"ly"
			},
			"LIGNE":{
				"factor":0.0021167,
				"label":"ligne"
			},
			"LIGNE_SWISS":{
				"factor":0.002256,
				"label":"ligne"
			},
			"LINE":{
				"factor":0.0021167,
				"label":"li"
			},
			"LINE_SMALL":{
				"factor":0.000635,
				"label":"li"
			},
			"LINK":{
				"factor":[
					{
						"op":"",
						"val":792
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"link"
			},
			"LINK_ENGINEER":{
				"factor":0.3048,
				"label":"link"
			},
			"LUG":{
				"factor":5.0292,
				"label":"lug"
			},
			"LUG_GREAT":{
				"factor":6.4008,
				"label":"lug"
			},
			"MARATHON":{
				"factor":42194.988,
				"label":"marathon"
			},
			"MARK_TWAIN":{
				"factor":3.6576074,
				"label":"mark twain"
			},
			"MEGAMETER":{
				"factor":1000000,
				"label":"Mm"
			},
			"MEGAPARSEC":{
				"factor":3.085677e+22,
				"label":"Mpc"
			},
			"MEILE_AUSTRIAN":{
				"factor":7586,
				"label":"meile"
			},
			"MEILE":{
				"factor":7412.7,
				"label":"meile"
			},
			"MEILE_GERMAN":{
				"factor":7532.5,
				"label":"meile"
			},
			"METER":{
				"factor":1,
				"label":"m"
			},
			"METRE":{
				"factor":1,
				"label":"m"
			},
			"METRIC_MILE":{
				"factor":1500,
				"label":"metric mile"
			},
			"METRIC_MILE_US":{
				"factor":1600,
				"label":"metric mile"
			},
			"MICROINCH":{
				"factor":2.54e-8,
				"label":"\u00b5in"
			},
			"MICROMETER":{
				"factor":1.0e-6,
				"label":"\u00b5m"
			},
			"MICROMICRON":{
				"factor":1.0e-12,
				"label":"\u00b5\u00b5"
			},
			"MICRON":{
				"factor":1.0e-6,
				"label":"\u00b5m"
			},
			"MIGLIO":{
				"factor":1488.6,
				"label":"miglio"
			},
			"MIIL":{
				"factor":7500,
				"label":"miil"
			},
			"MIIL_DENMARK":{
				"factor":7532.5,
				"label":"miil"
			},
			"MIIL_SWEDISH":{
				"factor":10687,
				"label":"miil"
			},
			"MIL":{
				"factor":2.54e-5,
				"label":"mil"
			},
			"MIL_SWEDISH":{
				"factor":10000,
				"label":"mil"
			},
			"MILE_UK":{
				"factor":1609,
				"label":"mi"
			},
			"MILE_IRISH":{
				"factor":2048,
				"label":"mi"
			},
			"MILE":{
				"factor":1609.344,
				"label":"mi"
			},
			"MILE_NAUTIC":{
				"factor":1852,
				"label":"mi"
			},
			"MILE_NAUTIC_UK":{
				"factor":1853.184,
				"label":"mi"
			},
			"MILE_NAUTIC_US":{
				"factor":1852,
				"label":"mi"
			},
			"MILE_ANCIENT":{
				"factor":1520,
				"label":"mi"
			},
			"MILE_SCOTTISH":{
				"factor":1814,
				"label":"mi"
			},
			"MILE_STATUTE":{
				"factor":1609.344,
				"label":"mi"
			},
			"MILE_US":{
				"factor":[
					{
						"op":"",
						"val":6336000
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"mi"
			},
			"MILHA":{
				"factor":2087.3,
				"label":"milha"
			},
			"MILITARY_PACE":{
				"factor":0.762,
				"label":"mil. pace"
			},
			"MILITARY_PACE_DOUBLE":{
				"factor":0.9144,
				"label":"mil. pace"
			},
			"MILLA":{
				"factor":1392,
				"label":"milla"
			},
			"MILLE":{
				"factor":1949,
				"label":"mille"
			},
			"MILLIARE":{
				"factor":0.001478,
				"label":"milliare"
			},
			"MILLIMETER":{
				"factor":0.001,
				"label":"mm"
			},
			"MILLIMICRON":{
				"factor":1.0e-9,
				"label":"m\u00b5"
			},
			"MKONO":{
				"factor":0.4572,
				"label":"mkono"
			},
			"MOOT":{
				"factor":0.0762,
				"label":"moot"
			},
			"MYRIAMETER":{
				"factor":10000,
				"label":"mym"
			},
			"NAIL":{
				"factor":0.05715,
				"label":"nail"
			},
			"NANOMETER":{
				"factor":1.0e-9,
				"label":"nm"
			},
			"NANON":{
				"factor":1.0e-9,
				"label":"nanon"
			},
			"PACE":{
				"factor":1.524,
				"label":"pace"
			},
			"PACE_ROMAN":{
				"factor":1.48,
				"label":"pace"
			},
			"PALM_DUTCH":{
				"factor":0.1,
				"label":"palm"
			},
			"PALM_UK":{
				"factor":0.075,
				"label":"palm"
			},
			"PALM":{
				"factor":0.2286,
				"label":"palm"
			},
			"PALMO_PORTUGUESE":{
				"factor":0.22,
				"label":"palmo"
			},
			"PALMO":{
				"factor":0.2,
				"label":"palmo"
			},
			"PALMO_US":{
				"factor":0.2117,
				"label":"palmo"
			},
			"PARASANG":{
				"factor":6000,
				"label":"parasang"
			},
			"PARIS_FOOT":{
				"factor":0.3248406,
				"label":"paris foot"
			},
			"PARSEC":{
				"factor":3.0856776e+16,
				"label":"pc"
			},
			"PE":{
				"factor":0.33324,
				"label":"p\u00e9"
			},
			"PEARL":{
				"factor":0.001757299,
				"label":"pearl"
			},
			"PERCH":{
				"factor":5.0292,
				"label":"perch"
			},
			"PERCH_IRELAND":{
				"factor":6.4008,
				"label":"perch"
			},
			"PERTICA":{
				"factor":2.96,
				"label":"pertica"
			},
			"PES":{
				"factor":0.2967,
				"label":"pes"
			},
			"PETAMETER":{
				"factor":1.0e+15,
				"label":"Pm"
			},
			"PICA":{
				"factor":0.0042175176,
				"label":"pi"
			},
			"PICOMETER":{
				"factor":1.0e-12,
				"label":"pm"
			},
			"PIE_ARGENTINA":{
				"factor":0.2889,
				"label":"pie"
			},
			"PIE_ITALIC":{
				"factor":0.298,
				"label":"pie"
			},
			"PIE":{
				"factor":0.2786,
				"label":"pie"
			},
			"PIE_US":{
				"factor":0.2822,
				"label":"pie"
			},
			"PIED_DE_ROI":{
				"factor":0.3248406,
				"label":"pied de roi"
			},
			"PIK":{
				"factor":0.71,
				"label":"pik"
			},
			"PIKE":{
				"factor":0.71,
				"label":"pike"
			},
			"POINT_ADOBE":{
				"factor":[
					{
						"op":"",
						"val":0.3048
					},
					{
						"op":"\/",
						"val":864
					}
				],
				"label":"pt"
			},
			"POINT_DIDOT":{
				"factor":0.000377,
				"label":"pt"
			},
			"POINT_TEX":{
				"factor":0.0003514598035,
				"label":"pt"
			},
			"POLE":{
				"factor":5.0292,
				"label":"pole"
			},
			"POLEGADA":{
				"factor":0.02777,
				"label":"polegada"
			},
			"POUCE":{
				"factor":0.02707,
				"label":"pouce"
			},
			"PU":{
				"factor":1.7907,
				"label":"pu"
			},
			"PULGADA":{
				"factor":0.02365,
				"label":"pulgada"
			},
			"PYGME":{
				"factor":0.346,
				"label":"pygme"
			},
			"Q":{
				"factor":0.00025,
				"label":"q"
			},
			"QUADRANT":{
				"factor":10001300,
				"label":"quad"
			},
			"QUARTER_CLOTH":{
				"factor":0.2286,
				"label":"Q"
			},
			"QUARTER_PRINT":{
				"factor":0.00025,
				"label":"Q"
			},
			"RANGE":{
				"factor":[
					{
						"op":"",
						"val":38016000
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"range"
			},
			"REED":{
				"factor":2.679,
				"label":"reed"
			},
			"RI":{
				"factor":3927,
				"label":"ri"
			},
			"RIDGE":{
				"factor":6.1722,
				"label":"ridge"
			},
			"RIVER":{
				"factor":2000,
				"label":"river"
			},
			"ROD":{
				"factor":5.0292,
				"label":"rd"
			},
			"ROD_SURVEY":{
				"factor":[
					{
						"op":"",
						"val":19800
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"rd"
			},
			"ROEDE":{
				"factor":10,
				"label":"roede"
			},
			"ROOD":{
				"factor":3.7783,
				"label":"rood"
			},
			"ROPE":{
				"factor":3.7783,
				"label":"rope"
			},
			"ROYAL_FOOT":{
				"factor":0.3248406,
				"label":"royal foot"
			},
			"RUTE":{
				"factor":3.75,
				"label":"rute"
			},
			"SADZHEN":{
				"factor":2.1336,
				"label":"sadzhen"
			},
			"SAGENE":{
				"factor":2.1336,
				"label":"sagene"
			},
			"SCOTS_FOOT":{
				"factor":0.30645,
				"label":"scots foot"
			},
			"SCOTS_MILE":{
				"factor":1814.2,
				"label":"scots mile"
			},
			"SEEMEILE":{
				"factor":1852,
				"label":"seemeile"
			},
			"SHACKLE":{
				"factor":27.432,
				"label":"shackle"
			},
			"SHAFTMENT":{
				"factor":0.15124,
				"label":"shaftment"
			},
			"SHAFTMENT_ANCIENT":{
				"factor":0.165,
				"label":"shaftment"
			},
			"SHAKU":{
				"factor":0.303,
				"label":"shaku"
			},
			"SIRIOMETER":{
				"factor":1.4959787e+17,
				"label":"siriometer"
			},
			"SMOOT":{
				"factor":1.7018,
				"label":"smoot"
			},
			"SPAN":{
				"factor":0.2286,
				"label":"span"
			},
			"SPAT":{
				"factor":1000000000000,
				"label":"spat"
			},
			"STADIUM":{
				"factor":185,
				"label":"stadium"
			},
			"STEP":{
				"factor":0.762,
				"label":"step"
			},
			"STICK":{
				"factor":3.048,
				"label":"stk"
			},
			"STORY":{
				"factor":3.3,
				"label":"story"
			},
			"STRIDE":{
				"factor":1.524,
				"label":"stride"
			},
			"STRIDE_ROMAN":{
				"factor":1.48,
				"label":"stride"
			},
			"TENTHMETER":{
				"factor":1.0e-10,
				"label":"tenth-meter"
			},
			"TERAMETER":{
				"factor":1000000000000,
				"label":"Tm"
			},
			"THOU":{
				"factor":2.54e-5,
				"label":"thou"
			},
			"TOISE":{
				"factor":1.949,
				"label":"toise"
			},
			"TOWNSHIP":{
				"factor":[
					{
						"op":"",
						"val":38016000
					},
					{
						"op":"\/",
						"val":3937
					}
				],
				"label":"twp"
			},
			"T_SUN":{
				"factor":0.0358,
				"label":"t'sun"
			},
			"TU":{
				"factor":161130,
				"label":"tu"
			},
			"TWAIN":{
				"factor":3.6576074,
				"label":"twain"
			},
			"TWIP":{
				"factor":1.7639e-5,
				"label":"twip"
			},
			"U":{
				"factor":0.04445,
				"label":"U"
			},
			"VARA_CALIFORNIA":{
				"factor":0.83820168,
				"label":"vara"
			},
			"VARA_MEXICAN":{
				"factor":0.83802,
				"label":"vara"
			},
			"VARA_PORTUGUESE":{
				"factor":1.1,
				"label":"vara"
			},
			"VARA_AMERICA":{
				"factor":0.864,
				"label":"vara"
			},
			"VARA":{
				"factor":0.83587,
				"label":"vara"
			},
			"VARA_TEXAS":{
				"factor":0.84666836,
				"label":"vara"
			},
			"VERGE":{
				"factor":0.9144,
				"label":"verge"
			},
			"VERSHOK":{
				"factor":0.04445,
				"label":"vershok"
			},
			"VERST":{
				"factor":1066.8,
				"label":"verst"
			},
			"WAH":{
				"factor":2,
				"label":"wah"
			},
			"WERST":{
				"factor":1066.8,
				"label":"werst"
			},
			"X_UNIT":{
				"factor":1.0020722e-13,
				"label":"Xu"
			},
			"YOCTOMETER":{
				"factor":1.0e-24,
				"label":"ym"
			},
			"YOTTAMETER":{
				"factor":1.0e+24,
				"label":"Ym"
			},
			"ZEPTOMETER":{
				"factor":1.0e-21,
				"label":"zm"
			},
			"ZETTAMETER":{
				"factor":1.0e+21,
				"label":"Zm"
			},
			"ZOLL":{
				"factor":0.02634,
				"label":"zoll"
			},
			"ZOLL_SWISS":{
				"factor":0.03,
				"label":"zoll"
			}
		}
	},
	"LIGHTNESS":{
		"STANDARD":"CANDELA_PER_SQUARE_METER",
		"UNITS":{
			"APOSTILB":{
				"factor":0.31830989,
				"label":"asb"
			},
			"BLONDEL":{
				"factor":0.31830989,
				"label":"blondel"
			},
			"CANDELA_PER_SQUARE_CENTIMETER":{
				"factor":10000,
				"label":"cd\/cm\u00b2"
			},
			"CANDELA_PER_SQUARE_FOOT":{
				"factor":10.76391,
				"label":"cd\/ft\u00b2"
			},
			"CANDELA_PER_SQUARE_INCH":{
				"factor":1550.00304,
				"label":"cd\/in\u00b2"
			},
			"CANDELA_PER_SQUARE_METER":{
				"factor":1,
				"label":"cd\/m\u00b2"
			},
			"FOOTLAMBERT":{
				"factor":3.4262591,
				"label":"ftL"
			},
			"KILOCANDELA_PER_SQUARE_CENTIMETER":{
				"factor":10000000,
				"label":"kcd\/cm\u00b2"
			},
			"KILOCANDELA_PER_SQUARE_FOOT":{
				"factor":10763.91,
				"label":"kcd\/ft\u00b2"
			},
			"KILOCANDELA_PER_SQUARE_INCH":{
				"factor":1550003.04,
				"label":"kcd\/in\u00b2"
			},
			"KILOCANDELA_PER_SQUARE_METER":{
				"factor":1000,
				"label":"kcd\/m\u00b2"
			},
			"LAMBERT":{
				"factor":3183.0989,
				"label":"L"
			},
			"MILLILAMBERT":{
				"factor":3.1830989,
				"label":"mL"
			},
			"NIT":{
				"factor":1,
				"label":"nt"
			},
			"STILB":{
				"factor":10000,
				"label":"sb"
			}
		}
	},
	"NUMBER":{
		"STANDARD":"DECIMAL",
		"UNITS":{
			"BINARY":{
				"factor":2,
				"label":"\u2475"
			},
			"TERNARY":{
				"factor":3,
				"label":"\u2476"
			},
			"QUATERNARY":{
				"factor":4,
				"label":"\u2477"
			},
			"QUINARY":{
				"factor":5,
				"label":"\u2478"
			},
			"SENARY":{
				"factor":6,
				"label":"\u2479"
			},
			"SEPTENARY":{
				"factor":7,
				"label":"\u247a"
			},
			"OCTAL":{
				"factor":8,
				"label":"\u247b"
			},
			"NONARY":{
				"factor":9,
				"label":"\u247c"
			},
			"DECIMAL":{
				"factor":10,
				"label":"\u247d"
			},
			"DUODECIMAL":{
				"factor":12,
				"label":"\u247f"
			},
			"HEXADECIMAL":{
				"factor":16,
				"label":"\u2483"
			},
			"ROMAN":{
				"factor":99,
				"label":""
			}
		}
	},
	"POWER":{
		"STANDARD":"WATT",
		"UNITS":{
			"ATTOWATT":{
				"factor":1.0e-18,
				"label":"aW"
			},
			"BTU_PER_HOUR":{
				"factor":0.29307197,
				"label":"BTU\/h"
			},
			"BTU_PER_MINUTE":{
				"factor":17.5843182,
				"label":"BTU\/m"
			},
			"BTU_PER_SECOND":{
				"factor":1055.059092,
				"label":"BTU\/s"
			},
			"CALORIE_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":11630
					},
					{
						"op":"*",
						"val":1.0e-7
					}
				],
				"label":"cal\/h"
			},
			"CALORIE_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":697800
					},
					{
						"op":"*",
						"val":1.0e-7
					}
				],
				"label":"cal\/m"
			},
			"CALORIE_PER_SECOND":{
				"factor":[
					{
						"op":"",
						"val":41868000
					},
					{
						"op":"*",
						"val":1.0e-7
					}
				],
				"label":"cal\/s"
			},
			"CENTIWATT":{
				"factor":0.01,
				"label":"cW"
			},
			"CHEVAL_VAPEUR":{
				"factor":735.49875,
				"label":"cv"
			},
			"CLUSEC":{
				"factor":1.3332237e-6,
				"label":"clusec"
			},
			"DECIWATT":{
				"factor":0.1,
				"label":"dW"
			},
			"DEKAWATT":{
				"factor":10,
				"label":"daW"
			},
			"DYNE_CENTIMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1.0e-7
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"dyn cm\/h"
			},
			"DYNE_CENTIMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1.0e-7
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"dyn cm\/m"
			},
			"DYNE_CENTIMETER_PER_SECOND":{
				"factor":1.0e-7,
				"label":"dyn cm\/s"
			},
			"ERG_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1.0e-7
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"erg\/h"
			},
			"ERG_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1.0e-7
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"erg\/m"
			},
			"ERG_PER_SECOND":{
				"factor":1.0e-7,
				"label":"erg\/s"
			},
			"EXAWATT":{
				"factor":1.0e+18,
				"label":"EW"
			},
			"FEMTOWATT":{
				"factor":1.0e-15,
				"label":"fW"
			},
			"FOOT_POUND_FORCE_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1.3558179
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"ft lb\/h"
			},
			"FOOT_POUND_FORCE_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1.3558179
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"ft lb\/m"
			},
			"FOOT_POUND_FORCE_PER_SECOND":{
				"factor":1.3558179,
				"label":"ft lb\/s"
			},
			"FOOT_POUNDAL_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":0.04214011
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"ft pdl\/h"
			},
			"FOOT_POUNDAL_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":0.04214011
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"ft pdl\/m"
			},
			"FOOT_POUNDAL_PER_SECOND":{
				"factor":0.04214011,
				"label":"ft pdl\/s"
			},
			"GIGAWATT":{
				"factor":1000000000,
				"label":"GW"
			},
			"GRAM_FORCE_CENTIMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":9.80665e-5
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"gf cm\/h"
			},
			"GRAM_FORCE_CENTIMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":9.80665e-5
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"gf cm\/m"
			},
			"GRAM_FORCE_CENTIMETER_PER_SECOND":{
				"factor":9.80665e-5,
				"label":"gf cm\/s"
			},
			"HECTOWATT":{
				"factor":100,
				"label":"hW"
			},
			"HORSEPOWER_INTERNATIONAL":{
				"factor":745.69987,
				"label":"hp"
			},
			"HORSEPOWER_ELECTRIC":{
				"factor":746,
				"label":"hp"
			},
			"HORSEPOWER":{
				"factor":735.49875,
				"label":"hp"
			},
			"HORSEPOWER_WATER":{
				"factor":746.043,
				"label":"hp"
			},
			"INCH_OUNCH_FORCE_REVOLUTION_PER_MINUTE":{
				"factor":0.00073948398,
				"label":"in ocf\/m"
			},
			"JOULE_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"J\/h"
			},
			"JOULE_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"J\/m"
			},
			"JOULE_PER_SECOND":{
				"factor":1,
				"label":"J\/s"
			},
			"KILOCALORIE_PER_HOUR":{
				"factor":1.163,
				"label":"kcal\/h"
			},
			"KILOCALORIE_PER_MINUTE":{
				"factor":69.78,
				"label":"kcal\/m"
			},
			"KILOCALORIE_PER_SECOND":{
				"factor":4186.8,
				"label":"kcal\/s"
			},
			"KILOGRAM_FORCE_METER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":9.80665
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"kgf m\/h"
			},
			"KILOGRAM_FORCE_METER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":9.80665
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"kfg m\/m"
			},
			"KILOGRAM_FORCE_METER_PER_SECOND":{
				"factor":9.80665,
				"label":"kfg m\/s"
			},
			"KILOPOND_METER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":9.80665
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"kp\/h"
			},
			"KILOPOND_METER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":9.80665
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"kp\/m"
			},
			"KILOPOND_METER_PER_SECOND":{
				"factor":9.80665,
				"label":"kp\/s"
			},
			"KILOWATT":{
				"factor":1000,
				"label":"kW"
			},
			"MEGAWATT":{
				"factor":1000000,
				"label":"MW"
			},
			"MICROWATT":{
				"factor":1.0e-6,
				"label":"\u00b5W"
			},
			"MILLION_BTU_PER_HOUR":{
				"factor":293071.07,
				"label":"mio BTU\/h"
			},
			"MILLIWATT":{
				"factor":0.001,
				"label":"mM"
			},
			"NANOWATT":{
				"factor":1.0e-9,
				"label":"nN"
			},
			"NEWTON_METER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"Nm\/h"
			},
			"NEWTON_METER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"Nm\/m"
			},
			"NEWTON_METER_PER_SECOND":{
				"factor":1,
				"label":"Nm\/s"
			},
			"PETAWATT":{
				"factor":1.0e+15,
				"label":"PW"
			},
			"PFERDESTAERKE":{
				"factor":735.49875,
				"label":"PS"
			},
			"PICOWATT":{
				"factor":1.0e-12,
				"label":"pW"
			},
			"PONCELET":{
				"factor":980.665,
				"label":"p"
			},
			"POUND_SQUARE_FOOT_PER_CUBIC_SECOND":{
				"factor":0.04214011,
				"label":"lb ft\u00b2\/s\u00b3"
			},
			"TERAWATT":{
				"factor":1000000000000,
				"label":"TW"
			},
			"TON_OF_REFRIGERATION":{
				"factor":3516.85284,
				"label":"RT"
			},
			"WATT":{
				"factor":1,
				"label":"W"
			},
			"YOCTOWATT":{
				"factor":1.0e-24,
				"label":"yW"
			},
			"YOTTAWATT":{
				"factor":1.0e+24,
				"label":"YW"
			},
			"ZEPTOWATT":{
				"factor":1.0e-21,
				"label":"zW"
			},
			"ZETTAWATT":{
				"factor":1.0e+21,
				"label":"ZW"
			}
		}
	},
	"PRESSURE":{
		"STANDARD":"NEWTON_PER_SQUARE_METER",
		"UNITS":{
			"ATMOSPHERE":{
				"factor":101325.01,
				"label":"atm"
			},
			"ATMOSPHERE_TECHNICAL":{
				"factor":98066.5,
				"label":"atm"
			},
			"ATTOBAR":{
				"factor":1.0e-13,
				"label":"ab"
			},
			"ATTOPASCAL":{
				"factor":1.0e-18,
				"label":"aPa"
			},
			"BAR":{
				"factor":100000,
				"label":"b"
			},
			"BARAD":{
				"factor":0.1,
				"label":"barad"
			},
			"BARYE":{
				"factor":0.1,
				"label":"ba"
			},
			"CENTIBAR":{
				"factor":1000,
				"label":"cb"
			},
			"CENTIHG":{
				"factor":1333.2239,
				"label":"cHg"
			},
			"CENTIMETER_MERCURY_0C":{
				"factor":1333.2239,
				"label":"cm mercury (0\u00b0C)"
			},
			"CENTIMETER_WATER_4C":{
				"factor":98.0665,
				"label":"cm water (4\u00b0C)"
			},
			"CENTIPASCAL":{
				"factor":0.01,
				"label":"cPa"
			},
			"CENTITORR":{
				"factor":1.3332237,
				"label":"cTorr"
			},
			"DECIBAR":{
				"factor":10000,
				"label":"db"
			},
			"DECIPASCAL":{
				"factor":0.1,
				"label":"dPa"
			},
			"DECITORR":{
				"factor":13.332237,
				"label":"dTorr"
			},
			"DEKABAR":{
				"factor":1000000,
				"label":"dab"
			},
			"DEKAPASCAL":{
				"factor":10,
				"label":"daPa"
			},
			"DYNE_PER_SQUARE_CENTIMETER":{
				"factor":0.1,
				"label":"dyn\/cm\u00b2"
			},
			"EXABAR":{
				"factor":1.0e+23,
				"label":"Eb"
			},
			"EXAPASCAL":{
				"factor":1.0e+18,
				"label":"EPa"
			},
			"FEMTOBAR":{
				"factor":1.0e-10,
				"label":"fb"
			},
			"FEMTOPASCAL":{
				"factor":1.0e-15,
				"label":"fPa"
			},
			"FOOT_AIR_0C":{
				"factor":3.8640888,
				"label":"ft air (0\u00b0C)"
			},
			"FOOT_AIR_15C":{
				"factor":3.6622931,
				"label":"ft air (15\u00b0C)"
			},
			"FOOT_HEAD":{
				"factor":2989.0669,
				"label":"ft head"
			},
			"FOOT_MERCURY_0C":{
				"factor":40636.664,
				"label":"ft mercury (0\u00b0C)"
			},
			"FOOT_WATER_4C":{
				"factor":2989.0669,
				"label":"ft water (4\u00b0C)"
			},
			"GIGABAR":{
				"factor":1.0e+14,
				"label":"Gb"
			},
			"GIGAPASCAL":{
				"factor":1000000000,
				"label":"GPa"
			},
			"GRAM_FORCE_SQUARE_CENTIMETER":{
				"factor":98.0665,
				"label":"gf"
			},
			"HECTOBAR":{
				"factor":10000000,
				"label":"hb"
			},
			"HECTOPASCAL":{
				"factor":100,
				"label":"hPa"
			},
			"INCH_AIR_0C":{
				"factor":[
					{
						"op":"",
						"val":3.8640888
					},
					{
						"op":"\/",
						"val":12
					}
				],
				"label":"in air (0\u00b0C)"
			},
			"INCH_AIR_15C":{
				"factor":[
					{
						"op":"",
						"val":3.6622931
					},
					{
						"op":"\/",
						"val":12
					}
				],
				"label":"in air (15\u00b0C)"
			},
			"INCH_MERCURY_0C":{
				"factor":[
					{
						"op":"",
						"val":40636.664
					},
					{
						"op":"\/",
						"val":12
					}
				],
				"label":"in mercury (0\u00b0C)"
			},
			"INCH_WATER_4C":{
				"factor":[
					{
						"op":"",
						"val":2989.0669
					},
					{
						"op":"\/",
						"val":12
					}
				],
				"label":"in water (4\u00b0C)"
			},
			"KILOBAR":{
				"factor":100000000,
				"label":"kb"
			},
			"KILOGRAM_FORCE_PER_SQUARE_CENTIMETER":{
				"factor":98066.5,
				"label":"kgf\/cm\u00b2"
			},
			"KILOGRAM_FORCE_PER_SQUARE_METER":{
				"factor":9.80665,
				"label":"kgf\/m\u00b2"
			},
			"KILOGRAM_FORCE_PER_SQUARE_MILLIMETER":{
				"factor":9806650,
				"label":"kgf\/mm\u00b2"
			},
			"KILONEWTON_PER_SQUARE_METER":{
				"factor":1000,
				"label":"kN\/m\u00b2"
			},
			"KILOPASCAL":{
				"factor":1000,
				"label":"kPa"
			},
			"KILOPOND_PER_SQUARE_CENTIMETER":{
				"factor":98066.5,
				"label":"kp\/cm\u00b2"
			},
			"KILOPOND_PER_SQUARE_METER":{
				"factor":9.80665,
				"label":"kp\/m\u00b2"
			},
			"KILOPOND_PER_SQUARE_MILLIMETER":{
				"factor":9806650,
				"label":"kp\/mm\u00b2"
			},
			"KIP_PER_SQUARE_FOOT":{
				"factor":[
					{
						"op":"",
						"val":430.92233
					},
					{
						"op":"\/",
						"val":0.009
					}
				],
				"label":"kip\/ft\u00b2"
			},
			"KIP_PER_SQUARE_INCH":{
				"factor":[
					{
						"op":"",
						"val":62052.81552
					},
					{
						"op":"\/",
						"val":0.009
					}
				],
				"label":"kip\/in\u00b2"
			},
			"MEGABAR":{
				"factor":100000000000,
				"label":"Mb"
			},
			"MEGANEWTON_PER_SQUARE_METER":{
				"factor":1000000,
				"label":"MN\/m\u00b2"
			},
			"MEGAPASCAL":{
				"factor":1000000,
				"label":"MPa"
			},
			"METER_AIR_0C":{
				"factor":12.677457,
				"label":"m air (0\u00b0C)"
			},
			"METER_AIR_15C":{
				"factor":12.015397,
				"label":"m air (15\u00b0C)"
			},
			"METER_HEAD":{
				"factor":9804.139432,
				"label":"m head"
			},
			"MICROBAR":{
				"factor":0.1,
				"label":"\u00b5b"
			},
			"MICROMETER_MERCURY_0C":{
				"factor":0.13332239,
				"label":"\u00b5m mercury (0\u00b0C)"
			},
			"MICROMETER_WATER_4C":{
				"factor":0.00980665,
				"label":"\u00b5m water (4\u00b0C)"
			},
			"MICRON_MERCURY_0C":{
				"factor":0.13332239,
				"label":"\u00b5 mercury (0\u00b0C)"
			},
			"MICROPASCAL":{
				"factor":1.0e-6,
				"label":"\u00b5Pa"
			},
			"MILLIBAR":{
				"factor":100,
				"label":"mb"
			},
			"MILLIHG":{
				"factor":133.32239,
				"label":"mHg"
			},
			"MILLIMETER_MERCURY_0C":{
				"factor":133.32239,
				"label":"mm mercury (0\u00b0C)"
			},
			"MILLIMETER_WATER_4C":{
				"factor":9.80665,
				"label":"mm water (0\u00b0C)"
			},
			"MILLIPASCAL":{
				"factor":0.001,
				"label":"mPa"
			},
			"MILLITORR":{
				"factor":0.13332237,
				"label":"mTorr"
			},
			"NANOBAR":{
				"factor":0.0001,
				"label":"nb"
			},
			"NANOPASCAL":{
				"factor":1.0e-9,
				"label":"nPa"
			},
			"NEWTON_PER_SQUARE_METER":{
				"factor":1,
				"label":"N\/m\u00b2"
			},
			"NEWTON_PER_SQUARE_MILLIMETER":{
				"factor":1000000,
				"label":"N\/mm\u00b2"
			},
			"OUNCE_PER_SQUARE_INCH":{
				"factor":430.92233,
				"label":"oz\/in\u00b2"
			},
			"PASCAL":{
				"factor":1,
				"label":"Pa"
			},
			"PETABAR":{
				"factor":1.0e+20,
				"label":"Pb"
			},
			"PETAPASCAL":{
				"factor":1.0e+15,
				"label":"PPa"
			},
			"PICOBAR":{
				"factor":1.0e-7,
				"label":"pb"
			},
			"PICOPASCAL":{
				"factor":1.0e-12,
				"label":"pPa"
			},
			"PIEZE":{
				"factor":1000,
				"label":"pz"
			},
			"POUND_PER_SQUARE_FOOT":{
				"factor":[
					{
						"op":"",
						"val":430.92233
					},
					{
						"op":"\/",
						"val":9
					}
				],
				"label":"lb\/ft\u00b2"
			},
			"POUND_PER_SQUARE_INCH":{
				"factor":6894.75728,
				"label":"lb\/in\u00b2"
			},
			"POUNDAL_PER_SQUARE_FOOT":{
				"factor":1.4881639,
				"label":"pdl\/ft\u00b2"
			},
			"STHENE_PER_SQUARE_METER":{
				"factor":1000,
				"label":"sn\/m\u00b2"
			},
			"TECHNICAL_ATMOSPHERE":{
				"factor":98066.5,
				"label":"at"
			},
			"TERABAR":{
				"factor":1.0e+17,
				"label":"Tb"
			},
			"TERAPASCAL":{
				"factor":1000000000000,
				"label":"TPa"
			},
			"TON_PER_SQUARE_FOOT":{
				"factor":[
					{
						"op":"",
						"val":120658.2524
					},
					{
						"op":"\/",
						"val":1.125
					}
				],
				"label":"t\/ft\u00b2"
			},
			"TON_PER_SQUARE_FOOT_SHORT":{
				"factor":[
					{
						"op":"",
						"val":430.92233
					},
					{
						"op":"\/",
						"val":0.0045
					}
				],
				"label":"t\/ft\u00b2"
			},
			"TON_PER_SQUARE_INCH":{
				"factor":[
					{
						"op":"",
						"val":17374788.3456
					},
					{
						"op":"\/",
						"val":1.125
					}
				],
				"label":"t\/in\u00b2"
			},
			"TON_PER_SQUARE_INCH_SHORT":{
				"factor":[
					{
						"op":"",
						"val":62052.81552
					},
					{
						"op":"\/",
						"val":0.0045
					}
				],
				"label":"t\/in\u00b2"
			},
			"TON_PER_SQUARE_METER":{
				"factor":9806.65,
				"label":"t\/m\u00b2"
			},
			"TORR":{
				"factor":133.32237,
				"label":"Torr"
			},
			"WATER_COLUMN_CENTIMETER":{
				"factor":98.0665,
				"label":"WC (cm)"
			},
			"WATER_COLUMN_INCH":{
				"factor":[
					{
						"op":"",
						"val":2989.0669
					},
					{
						"op":"\/",
						"val":12
					}
				],
				"label":"WC (in)"
			},
			"WATER_COLUMN_MILLIMETER":{
				"factor":9.80665,
				"label":"WC (mm)"
			},
			"YOCTOBAR":{
				"factor":1.0e-19,
				"label":"yb"
			},
			"YOCTOPASCAL":{
				"factor":1.0e-24,
				"label":"yPa"
			},
			"YOTTABAR":{
				"factor":1.0e+29,
				"label":"Yb"
			},
			"YOTTAPASCAL":{
				"factor":1.0e+24,
				"label":"YPa"
			},
			"ZEPTOBAR":{
				"factor":1.0e-16,
				"label":"zb"
			},
			"ZEPTOPASCAL":{
				"factor":1.0e-21,
				"label":"zPa"
			},
			"ZETTABAR":{
				"factor":1.0e+26,
				"label":"Zb"
			},
			"ZETTAPASCAL":{
				"factor":1.0e+21,
				"label":"ZPa"
			}
		}
	},
	"SPEED":{
		"STANDARD":"METER_PER_SECOND",
		"UNITS":{
			"BENZ":{
				"factor":1,
				"label":"Bz"
			},
			"CENTIMETER_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":0.01
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"cm\/day"
			},
			"CENTIMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":0.01
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"cm\/h"
			},
			"CENTIMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":0.01
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"cm\/m"
			},
			"CENTIMETER_PER_SECOND":{
				"factor":0.01,
				"label":"cd\/s"
			},
			"DEKAMETER_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":10
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"dam\/day"
			},
			"DEKAMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":10
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"dam\/h"
			},
			"DEKAMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":10
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"dam\/m"
			},
			"DEKAMETER_PER_SECOND":{
				"factor":10,
				"label":"dam\/s"
			},
			"FOOT_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":0.3048
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"ft\/day"
			},
			"FOOT_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":0.3048
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"ft\/h"
			},
			"FOOT_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":0.3048
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"ft\/m"
			},
			"FOOT_PER_SECOND":{
				"factor":0.3048,
				"label":"ft\/s"
			},
			"FURLONG_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":201.1684
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"fur\/day"
			},
			"FURLONG_PER_FORTNIGHT":{
				"factor":[
					{
						"op":"",
						"val":201.1684
					},
					{
						"op":"\/",
						"val":1209600
					}
				],
				"label":"fur\/fortnight"
			},
			"FURLONG_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":201.1684
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"fur\/h"
			},
			"FURLONG_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":201.1684
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"fur\/m"
			},
			"FURLONG_PER_SECOND":{
				"factor":201.1684,
				"label":"fur\/s"
			},
			"HECTOMETER_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":100
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"hm\/day"
			},
			"HECTOMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":100
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"hm\/h"
			},
			"HECTOMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":100
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"hm\/m"
			},
			"HECTOMETER_PER_SECOND":{
				"factor":100,
				"label":"hm\/s"
			},
			"INCH_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":0.0254
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"in\/day"
			},
			"INCH_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":0.0254
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"in\/h"
			},
			"INCH_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":0.0254
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"in\/m"
			},
			"INCH_PER_SECOND":{
				"factor":0.0254,
				"label":"in\/s"
			},
			"KILOMETER_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":1000
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"km\/day"
			},
			"KILOMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1000
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"km\/h"
			},
			"KILOMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1000
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"km\/m"
			},
			"KILOMETER_PER_SECOND":{
				"factor":1000,
				"label":"km\/s"
			},
			"KNOT":{
				"factor":[
					{
						"op":"",
						"val":1852
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"kn"
			},
			"LEAGUE_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":4828.0417
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"league\/day"
			},
			"LEAGUE_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":4828.0417
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"league\/h"
			},
			"LEAGUE_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":4828.0417
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"league\/m"
			},
			"LEAGUE_PER_SECOND":{
				"factor":4828.0417,
				"label":"league\/s"
			},
			"MACH":{
				"factor":340.29,
				"label":"M"
			},
			"MEGAMETER_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":1000000
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"Mm\/day"
			},
			"MEGAMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1000000
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"Mm\/h"
			},
			"MEGAMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1000000
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"Mm\/m"
			},
			"MEGAMETER_PER_SECOND":{
				"factor":1000000,
				"label":"Mm\/s"
			},
			"METER_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"m\/day"
			},
			"METER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"m\/h"
			},
			"METER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"m\/m"
			},
			"METER_PER_SECOND":{
				"factor":1,
				"label":"m\/s"
			},
			"MILE_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":1609.344
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"mi\/day"
			},
			"MILE_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1609.344
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"mi\/h"
			},
			"MILE_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1609.344
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"mi\/m"
			},
			"MILE_PER_SECOND":{
				"factor":1609.344,
				"label":"mi\/s"
			},
			"MILLIMETER_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":0.001
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"mm\/day"
			},
			"MILLIMETER_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":0.001
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"mm\/h"
			},
			"MILLIMETER_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":0.001
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"mm\/m"
			},
			"MILLIMETER_PER_SECOND":{
				"factor":0.001,
				"label":"mm\/s"
			},
			"MILLIMETER_PER_MICROSECOND":{
				"factor":1000,
				"label":"mm\/\u00b5s"
			},
			"MILLIMETER_PER_100_MICROSECOND":{
				"factor":10,
				"label":"mm\/100\u00b5s"
			},
			"NAUTIC_MILE_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":1852
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"nmi\/day"
			},
			"NAUTIC_MILE_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":1852
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"nmi\/h"
			},
			"NAUTIC_MILE_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":1852
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"nmi\/m"
			},
			"NAUTIC_MILE_PER_SECOND":{
				"factor":1852,
				"label":"nmi\/s"
			},
			"LIGHTSPEED_AIR":{
				"factor":299702547,
				"label":"speed of light (air)"
			},
			"LIGHTSPEED_GLASS":{
				"factor":199861638,
				"label":"speed of light (glass)"
			},
			"LIGHTSPEED_ICE":{
				"factor":228849204,
				"label":"speed of light (ice)"
			},
			"LIGHTSPEED_VACUUM":{
				"factor":299792458,
				"label":"speed of light (vacuum)"
			},
			"LIGHTSPEED_WATER":{
				"factor":225407863,
				"label":"speed of light (water)"
			},
			"SOUNDSPEED_AIT":{
				"factor":340.29,
				"label":"speed of sound (air)"
			},
			"SOUNDSPEED_METAL":{
				"factor":5000,
				"label":"speed of sound (metal)"
			},
			"SOUNDSPEED_WATER":{
				"factor":1500,
				"label":"speed of sound (water)"
			},
			"YARD_PER_DAY":{
				"factor":[
					{
						"op":"",
						"val":0.9144
					},
					{
						"op":"\/",
						"val":86400
					}
				],
				"label":"yd\/day"
			},
			"YARD_PER_HOUR":{
				"factor":[
					{
						"op":"",
						"val":0.9144
					},
					{
						"op":"\/",
						"val":3600
					}
				],
				"label":"yd\/h"
			},
			"YARD_PER_MINUTE":{
				"factor":[
					{
						"op":"",
						"val":0.9144
					},
					{
						"op":"\/",
						"val":60
					}
				],
				"label":"yd\/m"
			},
			"YARD_PER_SECOND":{
				"factor":0.9144,
				"label":"yd\/s"
			}
		}
	},
	"TEMPERATURE":{
		"STANDARD":"KELVIN",
		"UNITS":{
			"CELSIUS":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"+",
						"val":273.15
					}
				],
				"label":"\u00b0C"
			},
			"FAHRENHEIT":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"-",
						"val":32
					},
					{
						"op":"\/",
						"val":1.8
					},
					{
						"op":"+",
						"val":273.15
					}
				],
				"label":"\u00b0F"
			},
			"RANKINE":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"\/",
						"val":1.8
					}
				],
				"label":"\u00b0R"
			},
			"REAUMUR":{
				"factor":[
					{
						"op":"",
						"val":1
					},
					{
						"op":"*",
						"val":1.25
					},
					{
						"op":"+",
						"val":273.15
					}
				],
				"label":"\u00b0r"
			},
			"KELVIN":{
				"factor":1,
				"label":"\u00b0K"
			}
		}
	},
	"TIME":{
		"STANDARD":"SECOND",
		"UNITS":{
			"ANOMALISTIC_YEAR":{
				"factor":31558432,
				"label":"anomalistic year"
			},
			"ATTOSECOND":{
				"factor":1.0e-18,
				"label":"as"
			},
			"CENTURY":{
				"factor":3153600000,
				"label":"century"
			},
			"DAY":{
				"factor":86400,
				"label":"day"
			},
			"DECADE":{
				"factor":315360000,
				"label":"decade"
			},
			"DRACONIC_YEAR":{
				"factor":29947974,
				"label":"draconic year"
			},
			"EXASECOND":{
				"factor":1.0e+18,
				"label":"Es"
			},
			"FEMTOSECOND":{
				"factor":1.0e-15,
				"label":"fs"
			},
			"FORTNIGHT":{
				"factor":1209600,
				"label":"fortnight"
			},
			"GAUSSIAN_YEAR":{
				"factor":31558196,
				"label":"gaussian year"
			},
			"GIGASECOND":{
				"factor":1000000000,
				"label":"Gs"
			},
			"GREAT_YEAR":{
				"factor":[
					{
						"op":"*",
						"val":25700
					}
				],
				"label":"great year"
			},
			"GREGORIAN_YEAR":{
				"factor":31536000,
				"label":"year"
			},
			"HOUR":{
				"factor":3600,
				"label":"h"
			},
			"JULIAN_YEAR":{
				"factor":31557600,
				"label":"a"
			},
			"KILOSECOND":{
				"factor":1000,
				"label":"ks"
			},
			"LEAPYEAR":{
				"factor":31622400,
				"label":"year"
			},
			"MEGASECOND":{
				"factor":1000000,
				"label":"Ms"
			},
			"MICROSECOND":{
				"factor":1.0e-6,
				"label":"\u00b5s"
			},
			"MILLENIUM":{
				"factor":31536000000,
				"label":"millenium"
			},
			"MILLISECOND":{
				"factor":0.001,
				"label":"ms"
			},
			"MINUTE":{
				"factor":60,
				"label":"min"
			},
			"MONTH":{
				"factor":2628600,
				"label":"month"
			},
			"NANOSECOND":{
				"factor":1.0e-9,
				"label":"ns"
			},
			"PETASECOND":{
				"factor":1.0e+15,
				"label":"Ps"
			},
			"PICOSECOND":{
				"factor":1.0e-12,
				"label":"ps"
			},
			"SECOND":{
				"factor":1,
				"label":"s"
			},
			"SHAKE":{
				"factor":1.0e-9,
				"label":"shake"
			},
			"SIDEREAL_YEAR":{
				"factor":31558149.7676,
				"label":"sidereal year"
			},
			"TERASECOND":{
				"factor":1000000000000,
				"label":"Ts"
			},
			"TROPICAL_YEAR":{
				"factor":31556925,
				"label":"tropical year"
			},
			"WEEK":{
				"factor":604800,
				"label":"week"
			},
			"YEAR":{
				"factor":31536000,
				"label":"year"
			}
		}
	},
	"TORQUE":[

	],
	"VOLUME":{
		"STANDARD":"CUBIC_METER",
		"UNITS":{
			"ACRE_FOOT":{
				"factor":1233.48185532,
				"label":"ac ft"
			},
			"ACRE_FOOT_SURVEY":{
				"factor":1233.489,
				"label":"ac ft"
			},
			"ACRE_INCH":{
				"factor":102.79015461,
				"label":"ac in"
			},
			"BARREL_WINE":{
				"factor":0.143201835,
				"label":"bbl"
			},
			"BARREL":{
				"factor":0.16365924,
				"label":"bbl"
			},
			"BARREL_US_DRY":{
				"factor":[
					{
						"op":"",
						"val":26.7098656608
					},
					{
						"op":"\/",
						"val":231
					}
				],
				"label":"bbl"
			},
			"BARREL_US_FEDERAL":{
				"factor":0.1173477658,
				"label":"bbl"
			},
			"BARREL_US":{
				"factor":0.1192404717,
				"label":"bbl"
			},
			"BARREL_US_PETROLEUM":{
				"factor":0.1589872956,
				"label":"bbl"
			},
			"BOARD_FOOT":{
				"factor":[
					{
						"op":"",
						"val":6.5411915904
					},
					{
						"op":"\/",
						"val":2772
					}
				],
				"label":"board foot"
			},
			"BUCKET":{
				"factor":0.01818436,
				"label":"bucket"
			},
			"BUCKET_US":{
				"factor":0.018927059,
				"label":"bucket"
			},
			"BUSHEL":{
				"factor":0.03636872,
				"label":"bu"
			},
			"BUSHEL_US":{
				"factor":0.03523907,
				"label":"bu"
			},
			"CENTILITER":{
				"factor":1.0e-5,
				"label":"cl"
			},
			"CORD":{
				"factor":3.624556416,
				"label":"cd"
			},
			"CORD_FOOT":{
				"factor":0.453069552,
				"label":"cd ft"
			},
			"CUBIC_CENTIMETER":{
				"factor":1.0e-6,
				"label":"cm\u00b3"
			},
			"CUBIC_CUBIT":{
				"factor":0.144,
				"label":"cubit\u00b3"
			},
			"CUBIC_DECIMETER":{
				"factor":0.001,
				"label":"dm\u00b3"
			},
			"CUBIC_DEKAMETER":{
				"factor":1000,
				"label":"dam\u00b3"
			},
			"CUBIC_FOOT":{
				"factor":[
					{
						"op":"",
						"val":6.54119159
					},
					{
						"op":"\/",
						"val":231
					}
				],
				"label":"ft\u00b3"
			},
			"CUBIC_INCH":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":231
					}
				],
				"label":"in\u00b3"
			},
			"CUBIC_KILOMETER":{
				"factor":1000000000,
				"label":"km\u00b3"
			},
			"CUBIC_METER":{
				"factor":1,
				"label":"m\u00b3"
			},
			"CUBIC_MILE":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":231
					},
					{
						"op":"*",
						"val":3379200
					}
				],
				"label":"mi\u00b3"
			},
			"CUBIC_MICROMETER":{
				"factor":1.0e-18,
				"label":"\u00b5m\u00b3"
			},
			"CUBIC_MILLIMETER":{
				"factor":1.0e-9,
				"label":"mm\u00b3"
			},
			"CUBIC_YARD":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":231
					},
					{
						"op":"*",
						"val":46656
					}
				],
				"label":"yd\u00b3"
			},
			"CUP_CANADA":{
				"factor":0.0002273045,
				"label":"c"
			},
			"CUP":{
				"factor":0.00025,
				"label":"c"
			},
			"CUP_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":16
					}
				],
				"label":"c"
			},
			"DECILITER":{
				"factor":0.0001,
				"label":"dl"
			},
			"DEKALITER":{
				"factor":0.001,
				"label":"dal"
			},
			"DRUM_US":{
				"factor":0.208197649,
				"label":"drum"
			},
			"DRUM":{
				"factor":0.2,
				"label":"drum"
			},
			"FIFTH":{
				"factor":0.00075708236,
				"label":"fifth"
			},
			"GALLON":{
				"factor":0.00454609,
				"label":"gal"
			},
			"GALLON_US_DRY":{
				"factor":0.0044048838,
				"label":"gal"
			},
			"GALLON_US":{
				"factor":0.0037854118,
				"label":"gal"
			},
			"GILL":{
				"factor":[
					{
						"op":"",
						"val":0.00454609
					},
					{
						"op":"\/",
						"val":32
					}
				],
				"label":"gi"
			},
			"GILL_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":32
					}
				],
				"label":"gi"
			},
			"HECTARE_METER":{
				"factor":10000,
				"label":"ha m"
			},
			"HECTOLITER":{
				"factor":0.1,
				"label":"hl"
			},
			"HOGSHEAD":{
				"factor":0.28640367,
				"label":"hhd"
			},
			"HOGSHEAD_US":{
				"factor":0.2384809434,
				"label":"hhd"
			},
			"JIGGER":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":128
					},
					{
						"op":"*",
						"val":1.5
					}
				],
				"label":"jigger"
			},
			"KILOLITER":{
				"factor":1,
				"label":"kl"
			},
			"LITER":{
				"factor":0.001,
				"label":"l"
			},
			"MEASURE":{
				"factor":0.0077,
				"label":"measure"
			},
			"MEGALITER":{
				"factor":1000,
				"label":"Ml"
			},
			"MICROLITER":{
				"factor":1.0e-9,
				"label":"\u00b5l"
			},
			"MILLILITER":{
				"factor":1.0e-6,
				"label":"ml"
			},
			"MINIM":{
				"factor":[
					{
						"op":"",
						"val":0.00454609
					},
					{
						"op":"\/",
						"val":76800
					}
				],
				"label":"min"
			},
			"MINIM_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":61440
					}
				],
				"label":"min"
			},
			"OUNCE_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":128
					}
				],
				"label":"oz"
			},
			"PECK":{
				"factor":0.00909218,
				"label":"pk"
			},
			"PECK_US":{
				"factor":0.0088097676,
				"label":"pk"
			},
			"PINT":{
				"factor":[
					{
						"op":"",
						"val":0.00454609
					},
					{
						"op":"\/",
						"val":8
					}
				],
				"label":"pt"
			},
			"PINT_US_DRY":{
				"factor":[
					{
						"op":"",
						"val":0.0044048838
					},
					{
						"op":"\/",
						"val":8
					}
				],
				"label":"pt"
			},
			"PINT_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":8
					}
				],
				"label":"pt"
			},
			"PIPE":{
				"factor":0.49097772,
				"label":"pipe"
			},
			"PIPE_US":{
				"factor":0.4769618868,
				"label":"pipe"
			},
			"PONY":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":128
					}
				],
				"label":"pony"
			},
			"QUART_GERMANY":{
				"factor":0.00114504,
				"label":"qt"
			},
			"QUART_ANCIENT":{
				"factor":0.00108,
				"label":"qt"
			},
			"QUART":{
				"factor":[
					{
						"op":"",
						"val":0.00454609
					},
					{
						"op":"\/",
						"val":4
					}
				],
				"label":"qt"
			},
			"QUART_US_DRY":{
				"factor":[
					{
						"op":"",
						"val":0.0044048838
					},
					{
						"op":"\/",
						"val":4
					}
				],
				"label":"qt"
			},
			"QUART_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":4
					}
				],
				"label":"qt"
			},
			"QUART_UK":{
				"factor":0.29094976,
				"label":"qt"
			},
			"SHOT":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":128
					}
				],
				"label":"shot"
			},
			"STERE":{
				"factor":1,
				"label":"st"
			},
			"TABLESPOON":{
				"factor":1.5e-5,
				"label":"tbsp"
			},
			"TABLESPOON_UK":{
				"factor":[
					{
						"op":"",
						"val":0.00454609
					},
					{
						"op":"\/",
						"val":320
					}
				],
				"label":"tbsp"
			},
			"TABLESPOON_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":256
					}
				],
				"label":"tbsp"
			},
			"TEASPOON":{
				"factor":5.0e-6,
				"label":"tsp"
			},
			"TEASPOON_UK":{
				"factor":[
					{
						"op":"",
						"val":0.00454609
					},
					{
						"op":"\/",
						"val":1280
					}
				],
				"label":"tsp"
			},
			"TEASPOON_US":{
				"factor":[
					{
						"op":"",
						"val":0.0037854118
					},
					{
						"op":"\/",
						"val":768
					}
				],
				"label":"tsp"
			}
		}
	},
	"WEIGHT":{
		"STANDARD":"KILOGRAM",
		"UNITS":{
			"ARRATEL":{
				"factor":0.5,
				"label":"arratel"
			},
			"ARTEL":{
				"factor":0.5,
				"label":"artel"
			},
			"ARROBA_PORTUGUESE":{
				"factor":14.69,
				"label":"arroba"
			},
			"ARROBA":{
				"factor":11.502,
				"label":"@"
			},
			"AS_":{
				"factor":5.2e-5,
				"label":"as"
			},
			"ASS":{
				"factor":5.2e-5,
				"label":"ass"
			},
			"ATOMIC_MASS_UNIT_1960":{
				"factor":1.6603145e-27,
				"label":"amu"
			},
			"ATOMIC_MASS_UNIT_1973":{
				"factor":1.6605655e-27,
				"label":"amu"
			},
			"ATOMIC_MASS_UNIT_1986":{
				"factor":1.6605402e-27,
				"label":"amu"
			},
			"ATOMIC_MASS_UNIT":{
				"factor":1.66053873e-27,
				"label":"amu"
			},
			"AVOGRAM":{
				"factor":1.6605402e-27,
				"label":"avogram"
			},
			"BAG":{
				"factor":42.63768278,
				"label":"bag"
			},
			"BAHT":{
				"factor":0.015,
				"label":"baht"
			},
			"BALE":{
				"factor":326.5865064,
				"label":"bl"
			},
			"BALE_US":{
				"factor":217.7243376,
				"label":"bl"
			},
			"BISMAR_POUND":{
				"factor":5.993,
				"label":"bismar pound"
			},
			"CANDY":{
				"factor":254,
				"label":"candy"
			},
			"CARAT_INTERNATIONAL":{
				"factor":0.0002,
				"label":"ct"
			},
			"CARAT":{
				"factor":0.0002,
				"label":"ct"
			},
			"CARAT_UK":{
				"factor":0.00025919564,
				"label":"ct"
			},
			"CARAT_US_1913":{
				"factor":0.0002053,
				"label":"ct"
			},
			"CARGA":{
				"factor":140,
				"label":"carga"
			},
			"CATTI":{
				"factor":0.604875,
				"label":"catti"
			},
			"CATTI_JAPANESE":{
				"factor":0.594,
				"label":"catti"
			},
			"CATTY":{
				"factor":0.5,
				"label":"catty"
			},
			"CATTY_JAPANESE":{
				"factor":0.6,
				"label":"catty"
			},
			"CATTY_THAI":{
				"factor":0.6,
				"label":"catty"
			},
			"CENTAL":{
				"factor":45.359237,
				"label":"cH"
			},
			"CENTIGRAM":{
				"factor":1.0e-5,
				"label":"cg"
			},
			"CENTNER":{
				"factor":50,
				"label":"centner"
			},
			"CENTNER_RUSSIAN":{
				"factor":100,
				"label":"centner"
			},
			"CHALDER":{
				"factor":2692.52,
				"label":"chd"
			},
			"CHALDRON":{
				"factor":2692.52,
				"label":"chd"
			},
			"CHIN":{
				"factor":0.5,
				"label":"chin"
			},
			"CHIN_JAPANESE":{
				"factor":0.6,
				"label":"chin"
			},
			"CLOVE":{
				"factor":3.175,
				"label":"clove"
			},
			"CRITH":{
				"factor":8.9885e-5,
				"label":"crith"
			},
			"DALTON":{
				"factor":1.6605402e-27,
				"label":"D"
			},
			"DAN":{
				"factor":50,
				"label":"dan"
			},
			"DAN_JAPANESE":{
				"factor":60,
				"label":"dan"
			},
			"DECIGRAM":{
				"factor":0.0001,
				"label":"dg"
			},
			"DECITONNE":{
				"factor":100,
				"label":"dt"
			},
			"DEKAGRAM":{
				"factor":0.01,
				"label":"dag"
			},
			"DEKATONNE":{
				"factor":10000,
				"label":"dat"
			},
			"DENARO":{
				"factor":0.0011,
				"label":"denaro"
			},
			"DENIER":{
				"factor":0.001275,
				"label":"denier"
			},
			"DRACHME":{
				"factor":0.0038,
				"label":"drachme"
			},
			"DRAM_APOTHECARIES":{
				"factor":0.0038879346,
				"label":"dr"
			},
			"DYNE":{
				"factor":1.0197162e-6,
				"label":"dyn"
			},
			"ELECTRON":{
				"factor":9.109382e-31,
				"label":"e\u2212"
			},
			"ELECTRONVOLT":{
				"factor":1.782662e-36,
				"label":"eV"
			},
			"ETTO":{
				"factor":0.1,
				"label":"hg"
			},
			"EXAGRAM":{
				"factor":1.0e+15,
				"label":"Eg"
			},
			"FEMTOGRAM":{
				"factor":1.0e-18,
				"label":"fg"
			},
			"FIRKIN":{
				"factor":25.40117272,
				"label":"fir"
			},
			"FLASK":{
				"factor":34.7,
				"label":"flask"
			},
			"FOTHER":{
				"factor":979.7595192,
				"label":"fother"
			},
			"FOTMAL":{
				"factor":32.65865064,
				"label":"fotmal"
			},
			"FUNT":{
				"factor":0.4095,
				"label":"funt"
			},
			"FUNTE":{
				"factor":0.4095,
				"label":"funte"
			},
			"GAMMA":{
				"factor":1.0e-9,
				"label":"gamma"
			},
			"GIGAELECTRONVOLT":{
				"factor":1.782662e-27,
				"label":"GeV"
			},
			"GIGAGRAM":{
				"factor":1000000,
				"label":"Gg"
			},
			"GIGATONNE":{
				"factor":1000000000000,
				"label":"Gt"
			},
			"GIN":{
				"factor":0.6,
				"label":"gin"
			},
			"GIN_JAPANESE":{
				"factor":0.594,
				"label":"gin"
			},
			"GRAIN":{
				"factor":6.479891e-5,
				"label":"gr"
			},
			"GRAM":{
				"factor":0.001,
				"label":"g"
			},
			"GRAN":{
				"factor":0.00082,
				"label":"gran"
			},
			"GRANO":{
				"factor":4.905e-5,
				"label":"grano"
			},
			"GRANI":{
				"factor":4.905e-5,
				"label":"grani"
			},
			"GROS":{
				"factor":0.003824,
				"label":"gros"
			},
			"HECTOGRAM":{
				"factor":0.1,
				"label":"hg"
			},
			"HUNDRETWEIGHT":{
				"factor":50.80234544,
				"label":"cwt"
			},
			"HUNDRETWEIGHT_US":{
				"factor":45.359237,
				"label":"cwt"
			},
			"HYL":{
				"factor":9.80665,
				"label":"hyl"
			},
			"JIN":{
				"factor":0.5,
				"label":"jin"
			},
			"JUPITER":{
				"factor":1.899e+27,
				"label":"jupiter"
			},
			"KATI":{
				"factor":0.5,
				"label":"kati"
			},
			"KATI_JAPANESE":{
				"factor":0.6,
				"label":"kati"
			},
			"KEEL":{
				"factor":21540.19446656,
				"label":"keel"
			},
			"KEG":{
				"factor":45.359237,
				"label":"keg"
			},
			"KILODALTON":{
				"factor":1.6605402e-24,
				"label":"kD"
			},
			"KILOGRAM":{
				"factor":1,
				"label":"kg"
			},
			"KILOGRAM_FORCE":{
				"factor":1,
				"label":"kgf"
			},
			"KILOTON":{
				"factor":1016046.9088,
				"label":"kt"
			},
			"KILOTON_US":{
				"factor":907184.74,
				"label":"kt"
			},
			"KILOTONNE":{
				"factor":1000000,
				"label":"kt"
			},
			"KIN":{
				"factor":0.6,
				"label":"kin"
			},
			"KIP":{
				"factor":453.59237,
				"label":"kip"
			},
			"KOYAN":{
				"factor":2419,
				"label":"koyan"
			},
			"KWAN":{
				"factor":3.75,
				"label":"kwan"
			},
			"LAST_GERMANY":{
				"factor":2000,
				"label":"last"
			},
			"LAST":{
				"factor":1814.36948,
				"label":"last"
			},
			"LAST_WOOL":{
				"factor":1981.29147216,
				"label":"last"
			},
			"LB":{
				"factor":0.45359237,
				"label":"lb"
			},
			"LBS":{
				"factor":0.45359237,
				"label":"lbs"
			},
			"LIANG":{
				"factor":0.05,
				"label":"liang"
			},
			"LIBRE_ITALIAN":{
				"factor":0.339,
				"label":"lb"
			},
			"LIBRA_SPANISH":{
				"factor":0.459,
				"label":"lb"
			},
			"LIBRA_PORTUGUESE":{
				"factor":0.459,
				"label":"lb"
			},
			"LIBRA_ANCIENT":{
				"factor":0.323,
				"label":"lb"
			},
			"LIBRA":{
				"factor":1,
				"label":"lb"
			},
			"LIVRE":{
				"factor":0.4895,
				"label":"livre"
			},
			"LONG_TON":{
				"factor":1016.0469088,
				"label":"t"
			},
			"LOT":{
				"factor":0.015,
				"label":"lot"
			},
			"MACE":{
				"factor":0.003778,
				"label":"mace"
			},
			"MAHND":{
				"factor":0.9253284348,
				"label":"mahnd"
			},
			"MARC":{
				"factor":0.24475,
				"label":"marc"
			},
			"MARCO":{
				"factor":0.23,
				"label":"marco"
			},
			"MARK":{
				"factor":0.2268,
				"label":"mark"
			},
			"MARK_GERMANY":{
				"factor":0.2805,
				"label":"mark"
			},
			"MAUND":{
				"factor":37.3242,
				"label":"maund"
			},
			"MAUND_PAKISTAN":{
				"factor":40,
				"label":"maund"
			},
			"MEGADALTON":{
				"factor":1.6605402e-21,
				"label":"MD"
			},
			"MEGAGRAM":{
				"factor":1000,
				"label":"Mg"
			},
			"MEGATONNE":{
				"factor":1000000000,
				"label":"Mt"
			},
			"MERCANTILE_POUND":{
				"factor":0.46655,
				"label":"lb merc"
			},
			"METRIC_TON":{
				"factor":1000,
				"label":"t"
			},
			"MIC":{
				"factor":1.0e-9,
				"label":"mic"
			},
			"MICROGRAM":{
				"factor":1.0e-9,
				"label":"\ufffdg"
			},
			"MILLIDALTON":{
				"factor":1.6605402e-30,
				"label":"mD"
			},
			"MILLIER":{
				"factor":1000,
				"label":"millier"
			},
			"MILLIGRAM":{
				"factor":1.0e-6,
				"label":"mg"
			},
			"MILLIMASS_UNIT":{
				"factor":1.6605402e-30,
				"label":"mmu"
			},
			"MINA":{
				"factor":0.499,
				"label":"mina"
			},
			"MOMME":{
				"factor":0.00375,
				"label":"momme"
			},
			"MYRIAGRAM":{
				"factor":10,
				"label":"myg"
			},
			"NANOGRAM":{
				"factor":1.0e-12,
				"label":"ng"
			},
			"NEWTON":{
				"factor":0.101971621,
				"label":"N"
			},
			"OBOL":{
				"factor":0.0001,
				"label":"obol"
			},
			"OBOLOS":{
				"factor":0.0001,
				"label":"obolos"
			},
			"OBOLUS":{
				"factor":0.0001,
				"label":"obolus"
			},
			"OBOLOS_ANCIENT":{
				"factor":0.0005,
				"label":"obolos"
			},
			"OBOLUS_ANCIENT":{
				"factor":0.00057,
				"label":"obolos"
			},
			"OKA":{
				"factor":1.28,
				"label":"oka"
			},
			"ONCA":{
				"factor":0.02869,
				"label":"onca"
			},
			"ONCE":{
				"factor":0.03059,
				"label":"once"
			},
			"ONCIA":{
				"factor":0.0273,
				"label":"oncia"
			},
			"ONZA":{
				"factor":0.02869,
				"label":"onza"
			},
			"ONS":{
				"factor":0.1,
				"label":"ons"
			},
			"OUNCE_FORCE":{
				"factor":[
					{
						"op":"",
						"val":0.45359237
					},
					{
						"op":"\/",
						"val":16
					}
				],
				"label":"ozf"
			},
			"OUNCE_TROY":{
				"factor":[
					{
						"op":"",
						"val":65.31730128
					},
					{
						"op":"\/",
						"val":2100
					}
				],
				"label":"oz"
			},
			"PACKEN":{
				"factor":490.79,
				"label":"packen"
			},
			"PENNYWEIGHT":{
				"factor":[
					{
						"op":"",
						"val":65.31730128
					},
					{
						"op":"\/",
						"val":42000
					}
				],
				"label":"dwt"
			},
			"PETAGRAM":{
				"factor":1000000000000,
				"label":"Pg"
			},
			"PFUND":{
				"factor":0.5,
				"label":"pfd"
			},
			"PICOGRAM":{
				"factor":1.0e-15,
				"label":"pg"
			},
			"POND":{
				"factor":0.5,
				"label":"pond"
			},
			"POUND":{
				"factor":0.45359237,
				"label":"lb"
			},
			"POUND_FORCE":{
				"factor":0.4535237,
				"label":"lbf"
			},
			"POUND_METRIC":{
				"factor":0.5,
				"label":"lb"
			},
			"POUND_TROY":{
				"factor":[
					{
						"op":"",
						"val":65.31730128
					},
					{
						"op":"\/",
						"val":175
					}
				],
				"label":"lb"
			},
			"PUD":{
				"factor":16.3,
				"label":"pud"
			},
			"POOD":{
				"factor":16.3,
				"label":"pood"
			},
			"PUND":{
				"factor":0.5,
				"label":"pund"
			},
			"QIAN":{
				"factor":0.005,
				"label":"qian"
			},
			"QINTAR":{
				"factor":50,
				"label":"qintar"
			},
			"QUARTER_US":{
				"factor":11.33980925,
				"label":"qtr"
			},
			"QUARTER_TON":{
				"factor":226.796185,
				"label":"qtr"
			},
			"QUARTERN":{
				"factor":1.587573295,
				"label":"quartern"
			},
			"QUARTERN_LOAF":{
				"factor":1.81436948,
				"label":"quartern-loaf"
			},
			"QUINTAL_FRENCH":{
				"factor":48.95,
				"label":"q"
			},
			"QUINTAL":{
				"factor":100,
				"label":"q"
			},
			"QUINTAL_PORTUGUESE":{
				"factor":58.752,
				"label":"q"
			},
			"QUINTAL_SPAIN":{
				"factor":45.9,
				"label":"q"
			},
			"REBAH":{
				"factor":0.2855,
				"label":"rebah"
			},
			"ROTL":{
				"factor":0.5,
				"label":"rotl"
			},
			"ROTEL":{
				"factor":0.5,
				"label":"rotel"
			},
			"ROTTLE":{
				"factor":0.5,
				"label":"rottle"
			},
			"RATEL":{
				"factor":0.5,
				"label":"ratel"
			},
			"SACK":{
				"factor":165.10762268,
				"label":"sack"
			},
			"SCRUPLE":{
				"factor":[
					{
						"op":"",
						"val":65.31730128
					},
					{
						"op":"\/",
						"val":50400
					}
				],
				"label":"s"
			},
			"SEER":{
				"factor":0.933105,
				"label":"seer"
			},
			"SEER_PAKISTAN":{
				"factor":1,
				"label":"seer"
			},
			"SHEKEL":{
				"factor":0.01142,
				"label":"shekel"
			},
			"SHORT_TON":{
				"factor":907.18474,
				"label":"st"
			},
			"SLINCH":{
				"factor":175.126908,
				"label":"slinch"
			},
			"SLUG":{
				"factor":14.593903,
				"label":"slug"
			},
			"STONE":{
				"factor":6.35029318,
				"label":"st"
			},
			"TAEL":{
				"factor":0.03751,
				"label":"tael"
			},
			"TAHIL_JAPANESE":{
				"factor":0.03751,
				"label":"tahil"
			},
			"TAHIL":{
				"factor":0.05,
				"label":"tahil"
			},
			"TALENT":{
				"factor":30,
				"label":"talent"
			},
			"TAN":{
				"factor":50,
				"label":"tan"
			},
			"TECHNISCHE_MASS_EINHEIT":{
				"factor":9.80665,
				"label":"TME"
			},
			"TERAGRAM":{
				"factor":1000000000,
				"label":"Tg"
			},
			"TETRADRACHM":{
				"factor":0.014,
				"label":"tetradrachm"
			},
			"TICAL":{
				"factor":0.0164,
				"label":"tical"
			},
			"TOD":{
				"factor":12.70058636,
				"label":"tod"
			},
			"TOLA":{
				"factor":0.0116638125,
				"label":"tola"
			},
			"TOLA_PAKISTAN":{
				"factor":0.0125,
				"label":"tola"
			},
			"TON_UK":{
				"factor":1016.0469088,
				"label":"t"
			},
			"TON":{
				"factor":1000,
				"label":"t"
			},
			"TON_US":{
				"factor":907.18474,
				"label":"t"
			},
			"TONELADA_PORTUGUESE":{
				"factor":793.15,
				"label":"tonelada"
			},
			"TONELADA":{
				"factor":919.9,
				"label":"tonelada"
			},
			"TONNE":{
				"factor":1000,
				"label":"t"
			},
			"TONNEAU":{
				"factor":979,
				"label":"tonneau"
			},
			"TOVAR":{
				"factor":128.8,
				"label":"tovar"
			},
			"TROY_OUNCE":{
				"factor":[
					{
						"op":"",
						"val":65.31730128
					},
					{
						"op":"\/",
						"val":2100
					}
				],
				"label":"troy oz"
			},
			"TROY_POUND":{
				"factor":[
					{
						"op":"",
						"val":65.31730128
					},
					{
						"op":"\/",
						"val":175
					}
				],
				"label":"troy lb"
			},
			"TRUSS":{
				"factor":25.40117272,
				"label":"truss"
			},
			"UNCIA":{
				"factor":0.0272875,
				"label":"uncia"
			},
			"UNZE":{
				"factor":0.03125,
				"label":"unze"
			},
			"VAGON":{
				"factor":10000,
				"label":"vagon"
			},
			"YOCTOGRAM":{
				"factor":1.0e-27,
				"label":"yg"
			},
			"YOTTAGRAM":{
				"factor":1.0e+21,
				"label":"Yg"
			},
			"ZENTNER":{
				"factor":50,
				"label":"Ztr"
			},
			"ZEPTOGRAM":{
				"factor":1.0e-24,
				"label":"zg"
			},
			"ZETTAGRAM":{
				"factor":1.0e+18,
				"label":"Zg"
			}
		}
	}
};

    return _public;
})();